
import { defineComponent, PropType } from 'vue';

import { DndFileList, DndFileEntry } from '@/helpers/files';

const preventDefault = (e: any) => { e.preventDefault(); };

export default defineComponent({
  components: {
  },
  emits: ['onAddFiles', 'onDeleteFile'],
  data() {
    return {
      publicPath: process.env.BASE_URL, // <img :src="`${publicPath}icons/close.svg`" alt="X" />
      isHover: false
    };
  },
  props: {
    files: {
      type: Object as PropType<DndFileList>,
      required: true
    }
  },

  beforeMount: () => {
    document.body.addEventListener('dragenter', preventDefault);
    document.body.addEventListener('dragover', preventDefault);
    document.body.addEventListener('dragleave', preventDefault);
    document.body.addEventListener('drop', preventDefault);
  },

  beforeUnmount: () => {
    document.body.removeEventListener('dragenter', preventDefault);
    document.body.removeEventListener('dragover', preventDefault);
    document.body.removeEventListener('dragleave', preventDefault);
    document.body.removeEventListener('drop', preventDefault);
  },

  methods: {
    onDrop(e: any) {
      this.isHover = false;
      this.$emit('onAddFiles', e.dataTransfer.files);
      e.preventDefault();
    },
    onDragEnter() {
      this.isHover = true;
    },
    onDragLeave() {
      this.isHover = false;
    },
    onDeleteFile(file: DndFileEntry) {
      this.$emit('onDeleteFile', file);
    }
  }
});
