
import moment from 'moment';
import { defineComponent, inject } from 'vue';
import flatten from 'lodash/flatten';

import { SQ_PROVIDE_KEY } from '@/helpers/settings';
import { getAccountIssues } from '../requests/Accounts';
import { getTags } from '../requests/TicketFields';
import { getLatestTerm, getLatestCancellationTerm } from '../helpers/ParseAccounts';
import PolicyDetails from './PolicyDetails.vue';
import QuoteDetails from './QuoteDetails.vue';
import CollapsibleSection from './CollapsibleSection.vue';
import manageSubscriptions from '../helpers/manageSubscriptions';

// Due to how formatTermInfo is used, all three BOP values appear to be valid here
type Product = 'BOP' | 'BOP (Blackboard)' | 'BOP+' | 'XS' | 'WC' | '';

interface FormattedTerm {
  jobNumber: string;
  policyNumber: string;
  term: string;
  period: string;
  product: Product;
  status: string;
}

interface Quote {
  quoteNumber: string;
  product: Product;
  premium: string;
  updateDate: string;
  status: string;
}

const productMapping: Record<string, Product> = {
  'Businessowners Line (v7)': 'BOP',
  'Commercial Excess Liability Line': 'XS',
  "Workers' Comp Line": 'WC'
};

const quoteProductMapping: Record<string, Product> = {
  'Businessowners (v7)': 'BOP',
  'Commercial Excess Liability Policy': 'XS',
  "Workers' Compensation": 'WC'
};

const formatTermInfo = (term: any, status: string, lineOfBusiness?: Product): FormattedTerm => {
  const product = lineOfBusiness || productMapping[term.LineBusinessType as string] || '';

  return {
    jobNumber: term.Job.JobNumber,
    policyNumber: term.PolicyNumber,
    term: term.TermNumber,
    period: `${moment.utc(term.PeriodStart).format('M/D/YY')} - ${moment.utc(term.PeriodEnd).format('M/D/YY')}`,
    product,
    status
  };
};

export default defineComponent({
  data() {
    return {
      publicPath: process.env.BASE_URL,
      isBopPoliciesCollapsed: false,
      isWcPoliciesCollapsed: false,
      isBopQuotesCollapsed: false,
      isWcQuotesCollapsed: false,
      isIssuesCollapsed: true,
      issuesData: null,
      hasHelpCenterRequestIssue: false,
      hasError: false
    };
  },

  components: {
    PolicyDetails,
    QuoteDetails,
    CollapsibleSection
  },

  props: {
    currentUser: {
      type: Object
    },
    accountInfo: {
      type: Object,
      required: true
    },
    transactionData: {
      type: Object,
      required: true
    }
  },

  emits: ['navigated-account-section'],

  setup() {
    const { addNamedSubscription, removeNamedSubscription } = manageSubscriptions();
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);

    return {
      addNamedSubscription,
      removeNamedSubscription,
      sqBaseUrl
    };
  },

  async created() {
    const ticketTags = await getTags();
    this.hasHelpCenterRequestIssue = ticketTags.includes('help_center_request') && !ticketTags.includes('fully_automated');
  },

  watch: {
    accountInfo: {
      immediate: true,
      handler() {
        if (this.bopPolicies.length) {
          this.isBopQuotesCollapsed = true;
        } else {
          this.isBopQuotesCollapsed = false;
        }
        if (this.attuneWcPolicies.length) {
          this.isWcQuotesCollapsed = true;
        } else {
          this.isWcQuotesCollapsed = false;
        }
        this.hasError = false;
        this.addNamedSubscription(
          'issues',
          getAccountIssues(
            this.sqBaseUrl,
            this.accountInfo.AccountNumber
          ).subscribe(
            (issuesData) => {
              this.issuesData = issuesData;
            },
            () => {
              this.hasError = true;
            }
          )
        );
      }
    }
  },

  methods: {
    // Navigates to AccountSection, which navigates to root,
    // which ultimately redirects to HelpCenterRequest
    navigateToAccountSection(event) {
      this.$emit('navigated-account-section', event);
    },

    getLatestCancellationTerm(terms: any[]) {
      return terms.reduce((acc, nextTerm) => {
        const isCancellation = nextTerm.Job.Subtype === 'Cancellation';
        const isLater = acc ? moment(nextTerm.PeriodStart).isAfter(moment(acc.PeriodStart)) : true;
        if (isCancellation) {
          return isLater ? nextTerm : acc;
        }
        return acc;
      }, null);
    },

    toggleBopPoliciesCollapsed() {
      this.isBopPoliciesCollapsed = !this.isBopPoliciesCollapsed;
    },

    toggleWcPoliciesCollapsed() {
      this.isWcPoliciesCollapsed = !this.isWcPoliciesCollapsed;
    },

    toggleBopQuotesCollapsed() {
      this.isBopQuotesCollapsed = !this.isBopQuotesCollapsed;
    },

    toggleWcQuotesCollapsed() {
      this.isWcQuotesCollapsed = !this.isWcQuotesCollapsed;
    },

    toggleIssuesCollapsed() {
      this.isIssuesCollapsed = !this.isIssuesCollapsed;
    }
  },

  computed: {
    cancellationText(): string {
      const termPeriod = this.cancellationTermPeriod;
      if (termPeriod) {
        return `${termPeriod.PolicyNumber} is ${termPeriod.Status.toLowerCase()} (Reason: ${termPeriod.CancelReasonCode})`;
      } else {
        return '';
      }
    },

    cancellationTermPeriod(): any {
      const termPeriods = this?.transactionData?.PolicyPeriod?.PolicyTerm?.PortalViewableTermPeriods?.Entry;
      if (termPeriods) {
        const period = termPeriods.find(
          (termPeriod) => termPeriod.Status === 'Canceling'
        );
        return period || null;
      }
      return null;
    },

    issuesHeader(): string {
      let issueCount = this.outstandingUwAlerts.length;
      issueCount += this.hasThirdPartyDecline ? 1 : 0;
      issueCount += this.hasHelpCenterRequestIssue ? 1 : 0;
      if (issueCount === 1) {
        return '<b>1 issue</b> affecting this account';
      } else {
        return `<b>${issueCount} issues</b> affecting this account`;
      }
    },

    hasQuotes(): boolean {
      return !!this.bopQuotes.length
        || !!this.attuneWcQuotes.length
        || !!this.rolloverQuotes.length
        || !!this.renewalQuotes.length;
    },

    producerCodeList(): string {
      if (this.accountInfo) {
        return this.accountInfo.ProducerCodes.Entry.map(
          (entry) => entry.ProducerCode.Code
        ).join(', ');
      }
      return '';
    },

    accountContact(): string {
      if (this.accountInfo) {
        return this.accountInfo.AccountHolderContact.EmailAddress1;
      }
      return '';
    },

    quotes(): Quote[] {
      // Filter out "Withdrawn" quotes, which are quotes that were replaced with an edit
      let filteredQuotes = this.accountInfo.AllPoliciesSummary_HUSA.Entry.filter(
        (quote) => quote.Status !== 'Withdrawn'
      );
      // If there's an existing policy, any bound quote is a renewal - therefore, filter those out
      if (this.bopPolicies.length) {
        filteredQuotes = filteredQuotes.filter(
          (quote) => quote.Status !== 'Bound'
        );
      }

      const rolloverQuoteIds = this.rolloverQuotes.map((quote) => quote.quoteNumber);
      filteredQuotes = filteredQuotes.filter(
        (quote) => !rolloverQuoteIds.includes(quote.Job.JobNumber)
      );

      const quotesList = filteredQuotes.map((policy: any) => {
        const status = policy.Status === 'Draft' ? 'Declined' : policy.Status;
        return {
          quoteNumber: policy.Job.JobNumber,
          product: quoteProductMapping[policy.Product.DisplayName as string] || '',
          premium: policy.TotalCostRPT ? `$${policy.TotalCostRPT.toFixed(2)}` : 'N/A',
          updateDate: moment.utc(policy.Job.UpdateTime).format('M/D/YY'),
          status
        };
      });
      return quotesList;
    },

    bopQuotes(): Quote[] {
      return this.quotes.filter((quote) => ['BOP', 'BOP (Blackboard)', 'BOP+', 'XS'].includes(quote.product));
    },

    attuneWcQuotes(): Quote[] {
      return this.quotes.filter((quote) => quote.product === 'WC');
    },

    renewalQuotes(): Quote[] {
      const periods = this.accountInfo?.Policies?.Entry || [];
      const terms = flatten(periods.map((period) => period.PortalViewableNewTermPeriods.Entry));
      const quotedPoliciesList: Quote[] = terms.filter((term: any) => term.TermDisplayStatus_ATN === 'Quoted').map(
        (term: any) => ({
          quoteNumber: term.Job.JobNumber,
          product: 'BOP',
          premium: term.TotalCostRPT ? `$${term.TotalCostRPT.Amount.toFixed(2)}` : 'Unknown',
          updateDate: moment.utc(term.UpdateTime).format('M/D/YY'),
          status: 'Renewal (Quoted)'
        })
      );
      return quotedPoliciesList;
    },

    hasRolloverQuotes(): boolean {
      return !!this.rolloverQuotes.length;
    },

    rolloverQuotes(): Quote[] {
      if (!this.accountInfo?.AllPoliciesSummary_HUSA?.Entry?.length) {
        return [];
      }

      // BOP+ quotes are only rollover quotes if there is a bound Blackboard quote
      const blackboardQuotes = this.accountInfo.AllPoliciesSummary_HUSA.Entry.filter(
        (policy) => policy.UWCompanyBlackBoard && policy.Status !== 'Expired'
      );
      if (!blackboardQuotes.length) {
        return [];
      }

      return this.accountInfo.AllPoliciesSummary_HUSA.Entry.filter(
        (policy) => policy.UWCompanyAccredited && policy.Status !== 'Withdrawn'
      ).map((policy) => {
        return {
          quoteNumber: policy.Job.JobNumber,
          premium: policy.TotalCostRPT ? `$${policy.TotalCostRPT.toFixed(2)}` : 'N/A',
          updateDate: moment.utc(policy.Job.UpdateTime).format('M/D/YY'),
          status: policy.Status === 'Bound' ? 'BOP+ Rollover - Bound' : 'BOP+ Rollover - Quoted'
        };
      });
    },

    hasOutstandingUwAlerts(): boolean {
      return this?.issuesData?.uwAlerts?.filter((alert) => alert.status !== 'clear').length;
    },

    outstandingUwAlerts(): Record<string, string>[] {
      if (this.hasOutstandingUwAlerts) {
        return this.issuesData.uwAlerts.filter((alert) => alert.status !== 'clear');
      }
      return [];
    },

    hasThirdPartyDecline(): boolean {
      return this?.issuesData?.thirdPartyDeclineReasons?.length;
    },

    thirdPartDeclineReasons(): string[] {
      return this?.issuesData?.thirdPartyDeclineReasons;
    },

    isBlank(): boolean {
      return !this.hasQuotes &&
        !this.hasPolicies &&
        !this.accountInfo.hasGlQuote &&
        !this.accountInfo.hasPlQuote &&
        !this.accountInfo.hasWcQuote;
    },

    filteredPolicyList(): any[] {
      if (
        this.accountInfo &&
        this.accountInfo.Policies &&
        this.accountInfo.Policies.Entry.length
      ) {
        // filter out terms which include any expired quotes
        return this.accountInfo.Policies.Entry.filter((policy: any) => {
          return policy.PortalViewableNewTermPeriods.Entry.some(
            (term: any) => term.TermDisplayStatus_ATN !== 'Expired' || !!term.PolicyNumber
          );
        });
      }
      return [];
    },

    hasPolicies(): boolean {
      return !!this.policies.length;
    },

    policies(): FormattedTerm[] {
      const filteredPolicies = this.filteredPolicyList;
      const earlierPolicies: FormattedTerm[] = [];

      const formattedPolicies: FormattedTerm[] = filteredPolicies.map((policy: any) => {
        const terms = policy.PortalViewableNewTermPeriods.Entry;
        const latestTerm = getLatestTerm(policy);

        const matchingQuote = (this.accountInfo?.AllPoliciesSummary_HUSA?.Entry || []).find(
          (quote) => quote.PolicyNumber === latestTerm.PolicyNumber
        );
        let lineOfBusiness: Product = '';
        if (matchingQuote?.UWCompanyAccredited) {
          lineOfBusiness = 'BOP+';
        } else if (matchingQuote?.UWCompanyBlackBoard) {
          lineOfBusiness = 'BOP (Blackboard)';
        }

        if (latestTerm.TermDisplayStatus_ATN === 'In Force') {
          return formatTermInfo(latestTerm, 'In Force', lineOfBusiness);
        } else if (latestTerm.TermDisplayStatus_ATN === 'Expired') {
          return formatTermInfo(latestTerm, 'Expired', lineOfBusiness);
        } else if (latestTerm.TermDisplayStatus_ATN === 'Canceled') {
          const latestCancelTerm = getLatestCancellationTerm(policy);
          if (latestCancelTerm && latestCancelTerm.Status === 'Rescinded') {
            // TODO: this is based on portal-agent code, but I haven't seen accounts in this state yet
            return formatTermInfo(latestTerm, 'Reinstated', lineOfBusiness);
          } else {
            return formatTermInfo(latestTerm, 'Canceled', lineOfBusiness);
          }
        } else if (latestTerm.TermDisplayStatus_ATN === 'Renewing') {
          const inForcePolicy = terms.find((term) => term.TermDisplayStatus_ATN === 'In Force');
          if (inForcePolicy) {
            earlierPolicies.push(formatTermInfo(inForcePolicy, 'In Force', lineOfBusiness));
          }

          return formatTermInfo(latestTerm, 'Renewal (Quoted)');
        } else if (latestTerm.TermDisplayStatus_ATN === 'Scheduled') {
          const inForcePolicy = terms.find((term) => term.TermDisplayStatus_ATN === 'In Force');
          if (inForcePolicy) {
            earlierPolicies.push(formatTermInfo(inForcePolicy, 'In Force', lineOfBusiness));
          }

          return formatTermInfo(latestTerm, 'Scheduled');
        } else if (latestTerm.TermDisplayStatus_ATN === 'Non-renewed') {
          return formatTermInfo(latestTerm, 'Non-Renewed', lineOfBusiness);
        } else {
          return formatTermInfo(latestTerm, 'Unknown', lineOfBusiness);
        }
      });

      return flatten([formattedPolicies, earlierPolicies]);
    },

    bopPolicies(): FormattedTerm[] {
      return this.policies.filter((policy) => ['BOP', 'BOP (Blackboard)', 'BOP+', 'XS'].includes(policy.product));
    },

    attuneWcPolicies(): FormattedTerm[] {
      return this.policies.filter((policy) => policy.product === 'WC');
    },
  }
});
