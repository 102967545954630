
import { defineComponent, inject } from 'vue';

import { SQ_PROVIDE_KEY } from '@/helpers/settings';
import { UserInfo } from '@/helpers/types';
import { getAccountDetails, getPolicyTransactions } from '../requests/Accounts';

import AccountDetails from './AccountDetails.vue';
import AutofillSection from './AutofillSection.vue';
import BillingSection from './BillingSection.vue';
import CancellationSection from './CancellationSection.vue';
import ReinstatementSection from './ReinstatementSection.vue';
import { getLatestTerm } from '../helpers/ParseAccounts';
import { hasReinstatement } from '../helpers/flags';

import { trackEvent } from '../helpers/amplitude';
import manageSubscriptions from '../helpers/manageSubscriptions';

export default defineComponent({
  components: {
    AccountDetails,
    AutofillSection,
    BillingSection,
    CancellationSection,
    ReinstatementSection
  },

  props: {
    accountId: {
      type: String,
      required: true
    },
    currentUser: {
      type: Object
    },
    userOktaInfo: {
      type: Object
    }
  },

  emits: ['navigated-root'],

  setup() {
    const { addNamedSubscription, removeNamedSubscription } = manageSubscriptions();
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);
    return {
      addNamedSubscription,
      removeNamedSubscription,
      sqBaseUrl
    };
  },

  data() {
    return {
      inquiryType: '',
      accountInfo: null,
      accountSubscription: null,
      transactionData: null,
      isLoading: false,
      hasError: false,
      hasTransactionError: false
    };
  },

  watch: {
    accountId() {
      this.loadAccountData();
    },
  },

  methods: {
    reloadOnCancel() {
      // Add lag before reloading account info, because otherwise the cancellation will not have taken effect
      setTimeout(
        this.loadAccountData.bind(this),
        2000
      );
    },
    loadAccountData() {
      this.accountInfo = null;
      this.transactionData = null;
      this.isLoading = true;
      this.hasError = false;

      if (!this.accountId) {
        return;
      }

      this.accountSubscription = this.addNamedSubscription('accountDetails', getAccountDetails(this.sqBaseUrl, this.accountId).subscribe((accountInfo: any) => {
        this.isLoading = false;
        this.hasError = false;
        this.hasTransactionError = false;
        this.accountInfo = accountInfo;
        this.loadTransactionData();
      }, () => {
        this.hasError = true;
      }));
    },
    loadTransactionData() {
      const filteredPolicyList = this.accountInfo.Policies.Entry.filter((policy: any) => {
        return policy.PortalViewableNewTermPeriods.Entry.some(
          (term: any) => term.TermDisplayStatus_ATN !== 'Expired' || !!term.PolicyNumber
        );
      });

      if (filteredPolicyList.length) {
        const firstPolicy = filteredPolicyList[0];
        const latestTerm = getLatestTerm(firstPolicy);

        this.isLoading = true;
        this.addNamedSubscription(
          'transaction',
          getPolicyTransactions(
            this.sqBaseUrl,
            latestTerm.PolicyNumber,
            latestTerm.TermNumber
          ).subscribe(
            (transactionData) => {
              this.isLoading = false;
              this.transactionData = transactionData;
            },
            () => {
              this.isLoading = false;
              this.hasTransactionError = true;
            }
          )
        );
      }
    },
    selectAction(action: string) {
      this.inquiryType = action;
      if (action) {
        trackEvent('inquiry_select', action, 'AccountSection');
      } else {
        trackEvent('inquiry_deselect', '', 'AccountSection');
      }
    },
    // Navigates to root, which redirects to HelpCenterRequest
    navigateToRoot(event) {
      this.$emit('navigated-root', event);
    },
  },

  computed: {
    hasReinstatementPermission(): boolean {
      if (process.env.NODE_ENV === 'production') {
        return hasReinstatement(this.currentUser as UserInfo);
      }
      return true;
    }
  }
});
