
import { v4 as uuidv4 } from 'uuid';
import { defineComponent, inject } from 'vue';
import moment from 'moment';
import { SQ_PROVIDE_KEY } from '@/helpers/settings';
import { CANCEL_REASON_LABELS, cancelReasons } from '../helpers/cancellations';
import manageSubscriptions from '../helpers/manageSubscriptions';
import { HelpCenterRequestAutomationLevel } from '../helpers/HelpCenter';
import { getManualReviewReason, getTicketUrl } from '../requests/TicketFields';
import { getHelpCenterRequest, resolveHelpCenterRequest } from '../requests/HelpCenter';

export default defineComponent({
  props: {
    ticketId: {
      type: String
    },
    requestData: {
      type: Object
    },
    automationLevel: {
      type: String
    },
    currentUser: {
      type: Object
    }
  },

  data() {
    return {
      approved: false,
      rejected: false,
      resolving: false,
      resolutionError: false,
      requestDataDynamic: this.requestData,
      requestTypesRequiringQuote: ['Endorsement'],
      helpCenterManualReviewReason: '',
      cancelReasons,
      CANCEL_REASON_LABELS,
      otherCancelReason: '',
      otherCancelEffectiveDate: ''
    };
  },

  setup() {
    const { addSubscription } = manageSubscriptions();
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);

    return { addSubscription, sqBaseUrl };
  },

  methods: {
    setManualReviewReason() {
      this.addSubscription(
        getManualReviewReason(this.ticketId).subscribe(
          (reason: string) => {
            this.helpCenterManualReviewReason = reason;
          }
        )
      );
    },

    serializeOriginalTicket() {
      const ticket = this.requestData.originalTicket;
      const ticketUrl = getTicketUrl(ticket);
      return `<a href="${ticketUrl}" target="_blank">${ticket}</a>`;
    },

    identifyEndorsement(endorsement) {
      return `${endorsement.endorsementType}-${endorsement.changeType}-${uuidv4()}`;
    },

    serializeEndorsement(endorsement) {
      let type;
      switch (endorsement.endorsementType) {
        case 'Additionalinsured':
        default:
          type = 'AI';
          break;
      }
      const subtype = endorsement.changeType;
      return { type, subtype };
    },

    resolveRequest(action) {
      this.resolving = true;
      this.resolutionError = false;
      this.addSubscription(
        resolveHelpCenterRequest(
          this.sqBaseUrl,
          this.ticketId,
          this.requestData.requestId,
          action,
          this.currentUser.email,
          this.otherCancelReason,
          this.otherCancelEffectiveDate
        ).subscribe(
          () => {
            if (action === 'approve') {
              this.approved = true;
            } else if (action === 'reject') {
              this.rejected = true;
            }
            this.resolving = false;
            this.resolutionError = false;
          },
          () => {
            this.resolving = false;
            this.resolutionError = true;
          }
        )
      );
    },

    requiresQuote() {
      return this.requestTypesRequiringQuote.includes(this.requestData.requestInfo?.inquiryType);
    },

    fetchHelpCenterRequestData() {
      this.addSubscription(
        getHelpCenterRequest(
          this.sqBaseUrl,
          this.ticketId
        ).subscribe(
          (hcRequest: any) => {
            this.requestDataDynamic = hcRequest;
          }
        )
      );
    }
  },

  computed: {
    serializeFollowUpTickets(): string {
      if (this.requestDataDynamic.followUpTickets.length) {
        const ticketLinks = this.requestDataDynamic.followUpTickets.map((ticket) => {
          const ticketUrl = getTicketUrl(ticket);
          return `<a href="${ticketUrl}" target="_blank">${ticket}</a>`;
        });
        return ticketLinks.join(', ');
      }
      return 'N/A';
    },

    approvalStatus(): string {
      if (this.automationLevel === HelpCenterRequestAutomationLevel.FULL) {
        return 'N/A (automated)';
      }
      switch (this.requestDataDynamic.reviewStatus) {
        case 'approve':
          return 'Approved';
        case 'reject':
          return 'Rejected';
        case 'pending':
          return 'Pending review';
        default:
          // Note: this case should never be reached, since this
          // component is only displayed when review is needed
          return 'No review';
      }
    },

    reviewGiven(): boolean {
      return this.requestDataDynamic.reviewGiven;
    },

    reviewTimestamp(): string {
      return this.requestDataDynamic.reviewDate || 'Unrecorded';
    },

    reviewer(): string {
      return this.requestDataDynamic.reviewer || 'Unrecorded';
    },

    pendingReview(): boolean {
      return this.requestDataDynamic.reviewNeeded && !this.requestDataDynamic.reviewGiven;
    },

    actionable(): boolean {
      return (this.requiresQuote() && this.requestDataDynamic.requestInfo.quoted) || !this.requiresQuote();
    },

    isApproved(): boolean {
      return !this.requestDataDynamic.reviewNeeded || (this.requestDataDynamic.reviewNeeded && this.requestDataDynamic.reviewStatus === 'approve');
    },

    fullyAutomated(): boolean {
      return this.automationLevel === HelpCenterRequestAutomationLevel.FULL;
    },

    partiallyAutomated(): boolean {
      return this.automationLevel === HelpCenterRequestAutomationLevel.PARTIAL;
    },

    manual(): boolean {
      return this.automationLevel === HelpCenterRequestAutomationLevel.MANUAL;
    },

    requestConfirmation(): string {
      return this.requestDataDynamic.requestConfirmed ? 'Confirmed' : 'Unconfirmed';
    },

    requestExpiration(): string {
      return this.requestDataDynamic.requestExpired ? 'Expired' : 'Active';
    },

    reasonedCancellation(): boolean {
      return this.requestDataDynamic.requestInfo?.inquiryType === 'Cancellation' &&
        this.requestDataDynamic.requestInfo.cancelReason !== undefined;
    },

    otherCancellation(): boolean {
      return this.requestDataDynamic.requestInfo?.inquiryType === 'Cancellation' &&
        this.requestDataDynamic.requestInfo.otherCancelExplanation !== undefined;
    },

    pendingCancellation(): boolean {
      return this.requestDataDynamic.requestInfo?.inquiryType === 'Cancellation' &&
        this.requestDataDynamic.requestInfo.isPendingCancel;
    },

    approveDisabled(): boolean {
      if (this.otherCancellation || this.pendingCancellation) {
        return !this.otherCancelEffectiveDate || !this.otherCancelReason;
      }

      return false;
    },

    cancelReason(): string {
      return CANCEL_REASON_LABELS[this.requestDataDynamic.requestInfo.cancelReason];
    },

    cancelNotTaken(): boolean {
      return this.otherCancelReason === 'nottaken';
    },
  },

  watch: {
    ticketId() {
      this.setManualReviewReason();
    },

    requestData() {
      // Note: this is only necessary for the first page load
      // since requestDataDynamic would otherwise be undefined
      this.requestDataDynamic = this.requestData;

      if (this.otherCancellation || this.pendingCancellation) {
        this.otherCancelEffectiveDate = moment.utc(this.requestData.requestInfo.effectiveDate).format('YYYY-MM-DD');
      }
      if (this.pendingCancellation && !this.otherCancellation) {
        this.otherCancelReason = this.requestData.requestInfo.cancelReason;
      }
    },

    approved() {
      this.fetchHelpCenterRequestData();
    },

    rejected() {
      this.fetchHelpCenterRequestData();
    }
  }
});
