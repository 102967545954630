
import { ReadableInquiryTypeNames } from '@/helpers/HelpCenter';
import { SQ_PROVIDE_KEY } from '@/helpers/settings';
import * as Sentry from '@sentry/vue';
import { defineComponent, inject } from 'vue';
import FormStructureInterpreter from './FormStructureInterpreter.vue';
import { getEmailSummary } from '../requests/EmailSummary';

export default defineComponent({
  components: { FormStructureInterpreter },
  props: {
    formStructure: {
      type: Object
    },
    hasAttachments: {
      type: Boolean
    },
    inboundInquiryType: {
      type: String
    },
    ticketId: {
      type: String
    }
  },
  data() {
    return {
      rootInquiryFormNodes: [],
      generalFormNodes: [],
      emailSummary: '',
    };
  },
  setup() {
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);
    return { sqBaseUrl };
  },
  watch: {
    inboundInquiryType() {
      this.loadEmailSummary();
    },
    formStructure() {
      this.generateFormNodes();
    }
  },
  methods: {
    generateFormNodes() {
      this.generalFormNodes = this.formStructure.inquiryForms.formValues;
      this.rootInquiryFormNodes = this.formStructure.inquiryForms.forms[this.inboundInquiryType].formValues;
    },
    async loadEmailSummary() {
      getEmailSummary(this.sqBaseUrl, this.ticketId, this.inboundInquiryType)
        .subscribe((response: any) => {
          this.emailSummary = response.emailSummary.trim();
        }, () => {
          Sentry.captureException(new Error('Failed to retrieve email summary'));
        });
    },
  },
  computed: {
    getReadableInquiryType() {
      return ReadableInquiryTypeNames[this.inboundInquiryType];
    }
  }
});
