export interface UserInfo {
  email: string;
  name: string;
  role: string;
  groups: { id: number; name: string }[];
}
export interface CustomField {
  id: number;
  value: any;
}
export interface ZendeskTicket {
  id: number;
  url: string;
  subject: string;
  via: { channel: string };
  custom_fields: CustomField[];
}

// Zendesk Ticket Field data returned by SQ
export enum ZendeskValueType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  TAGGER = 'tagger',
  MULTISELECT = 'multiselect',
}

export interface ZendeskCustomFieldOption {
  value: string;
  name: string;
  raw_name: string;
}
export interface ZendeskTicketField {
  url: string;
  id: number;
  type: ZendeskValueType;
  title: string;
  custom_field_options: ZendeskCustomFieldOption[];
}

export interface ZendeskTicketComment {
  id: number;
  body: string;
  attachments: [];
  via: { channel: string };
}

// Field structure returned by SQ
// This is a very simplistic type just for clarity of the SQ data structure
export enum FieldType {
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  ADDRESS = 'ADDRESS',
  ANSWER = 'ANSWER',
}
export interface AddressFields {
  line1: number;
  line2: number;
  city: number;
  state: number;
  zip: number;
}
export interface Field {
  fieldType: FieldType;
  fieldId?: number;
  fieldName?: string;
  fieldLabel?: string;
  tip?: string;
  children?: Field[];
  answerValue?: string;
  answerLabel?: string;
  addressFields?: AddressFields
}
