
import { defineComponent, inject, ref } from 'vue';
import { searchAccounts } from '../requests/Accounts';

import { trackEvent } from '../helpers/amplitude';
import { SQ_PROVIDE_KEY } from '../helpers/settings';

export default defineComponent({
  beforeUnmount() {
    if (this.accountsSubscription) {
      this.accountsSubscription.unsubscribe();
    }
  },

  emits: ['selectedAccount:update'],

  setup() {
    const searchInput = ref(null);
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);
    return { searchInput, sqBaseUrl };
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      accountSearchString: '',
      hasError: false,
      selectedAccount: null,
      debounceTimeout: null,
      accountInfo: null,
      accountsSubscription: null,
    };
  },

  methods: {
    clearSearch() {
      this.searchInput.value = '';
      this.accountSearchString = '';
      this.deselectAccount();
      this.accountInfo = null;
    },

    updateSearchString(event: any) {
      if (this.accountSearchString === event.target.value) {
        return;
      }

      this.deselectAccount();
      this.accountSearchString = event.target.value;
      if (this.accountSearchString.length >= 4) {
        this.loadAccounts();
      }
    },

    selectAccount(account: any) {
      this.selectedAccount = account;
      trackEvent('account_select', account.accountNumber, 'AccountSelector');
      this.$emit('selectedAccount:update', account.accountNumber);
    },

    deselectAccount() {
      if (this.selectedAccount) {
        trackEvent('account_unselect', null, 'AccountSelector');
      }
      this.selectedAccount = null;
      this.$emit('selectedAccount:update', null);
    },

    loadAccounts() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        const currentQuery = this.accountSearchString;
        this.hasError = false;
        this.accountInfo = null;

        trackEvent('account_search', currentQuery, 'AccountSelector');

        if (this.accountsSubscription) {
          this.accountsSubscription.unsubscribe();
        }
        this.accountsSubscription = searchAccounts(this.sqBaseUrl, currentQuery).subscribe(
          (accountInfo) => {
            this.accountInfo = accountInfo;
          },
          () => {
            this.hasError = true;
          }
        );
      }, 400);
    }
  }
});
