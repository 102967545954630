import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e44f3db"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "dnd-upload-area"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "dnd-file-row" }
const _hoisted_4 = { class: "dnd-file-name" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src", "onClick"]
const _hoisted_8 = {
  key: 0,
  class: "dnd-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onDrop: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDrop && _ctx.onDrop(...args)), ["prevent"])),
    onDragenter: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDragEnter && _ctx.onDragEnter(...args)), ["prevent"])),
    onDragover: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDragEnter && _ctx.onDragEnter(...args)), ["prevent"])),
    onDragleave: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDragLeave && _ctx.onDragLeave(...args)), ["prevent"])),
    class: _normalizeClass(["dnd-upload", {
    'dnd-hover': _ctx.isHover,
    'dnd-empty': _ctx.files.items.length < 1 && !_ctx.isHover
  }])
  }, [
    (_ctx.files.items.length < 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Drag files here to upload "))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files.items, (file) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "dnd-file",
              key: file.file.name
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, [
                  _createTextVNode(_toDisplayString(file.file.name) + " ", 1),
                  (file.status === 1)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: `${_ctx.publicPath}icons/check_circle.svg`,
                        alt: "Success"
                      }, null, 8, _hoisted_5))
                    : _createCommentVNode("", true),
                  (file.status === 3)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        src: `${_ctx.publicPath}icons/error.svg`,
                        alt: "Success"
                      }, null, 8, _hoisted_6))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("img", {
                  class: "dnd-clickable",
                  src: `${_ctx.publicPath}icons/delete.svg`,
                  alt: "X",
                  role: "button",
                  onClick: ($event: any) => (_ctx.onDeleteFile(file))
                }, null, 8, _hoisted_7)
              ]),
              (file.status === 2 || file.status === 3)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(file.errorMessage), 1))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
  ], 34))
}