
import { computed, defineComponent, provide, reactive } from 'vue';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import * as Sentry from '@sentry/vue';
import AccountSelector from './components/AccountSelector.vue';
import AccountSection from './components/AccountSection.vue';
import UserSearch from './components/UserSearch.vue';
import ProducerSearch from './components/ProducerSearch.vue';
import HelpCenterRequestSection from './components/HelpCenterRequestSection.vue';
import SubmitDocuments from './components/SubmitDocuments.vue';
import TicketDataSection from './components/TicketDataSection.vue';
import EmailFormSection from './components/EmailFormSection.vue';
import ProductFeedbackSection from './components/ProductFeedbackSection.vue';
import ProductPromotionSection from './components/ProductPromotionSection.vue';
import { getCurrentUser } from './requests/CurrentUser';
import { hasProducerSearch, hasLobAccess, hasProductFeedback } from './helpers/flags';
import { identifyUser } from './helpers/amplitude';
import { HelpCenterRequestAutomationLevel, HCF_TICKET_DATA_VIEW_INQUIRY_TYPES } from './helpers/HelpCenter';
import { searchUsers } from './requests/Users';
import { getBranch, SQ_LOCALSTORAGE_KEY, SQ_PROVIDE_KEY, SQ_URLS_NONPROD, SQ_URL_BASE, SQ_URL_LABELS, ZENDESK_FIELD_IDS } from './helpers/settings';
import { getTicketComments } from './requests/TicketComments';
import { getAutomationLevel, getTicketData, getFieldValue, getFormStructure, getTags, getTicketOrganization, getTicketRequester } from './requests/TicketFields';
import { getHelpCenterRequest } from './requests/HelpCenter';
import { submitRolloverPacket, submitNonPayNotice, submitNonRenewalNotice, submitUWCancellationNotice } from './requests/Lob';
import { checkProductFeedback } from './requests/ProductFeedback';
import { CustomField, UserInfo } from './helpers/types';
import { getProducerCodeFromPhoneNumber } from './requests/Producers';

export default defineComponent({
  components: {
    AccountSelector,
    AccountSection,
    UserSearch,
    ProducerSearch,
    HelpCenterRequestSection,
    SubmitDocuments,
    TicketDataSection,
    EmailFormSection,
    ProductFeedbackSection,
    ProductPromotionSection,
  },
  data() {
    return {
      currentView: 'accounts',
      selectedAccount: '',
      ticketId: '',
      producerCode: '',
      userId: '',
      helpCenterRequestData: null,
      helpCenterRequestAutomationLevel: HelpCenterRequestAutomationLevel.MANUAL,
      currentUser: null as UserInfo | null,
      currentUserOktaInfo: null,
      oktaInfoNotFound: false,
      SQ_URLS_NONPROD,
      SQ_URL_LABELS,
      needsPageRefresh: false,
      customFieldValues: {},
      formStructure: null,
      showHcfDataView: false,
      showEmailFormView: false,
      showProductFeedback: false,
      isProductFeedbackChannel: false,
      formInboundInquiryType: '',
      hasAttachments: false,
    };
  },
  setup() {
    let sqDefaultUrl;

    if (process.env.NODE_ENV === 'production') {
      sqDefaultUrl = SQ_URL_BASE;
    } else {
      try {
        const localBaseSq = localStorage.getItem(SQ_LOCALSTORAGE_KEY);
        sqDefaultUrl = localBaseSq || SQ_URL_BASE;
      } catch (e) {
        sqDefaultUrl = SQ_URL_BASE;
      }
    }

    const state = reactive({ sqBaseUrl: sqDefaultUrl });
    provide(SQ_PROVIDE_KEY, computed(() => state.sqBaseUrl));

    return { state };
  },
  async created() {
    const [
      ticketTags,
      organization,
      requester,
    ] = await Promise.all([
      getTags(),
      getTicketOrganization(),
      getTicketRequester(),
    ]);

    const isHelpCenterRequest = ticketTags.includes('help_center_request');

    this.producerCode = organization?.organizationFields?.producer_code;
    const phoneIdentity = requester?.identities?.find((identity: any) => identity.type === 'phone_number');
    if (this.producerCode && requester?.email) {
      this.userId = requester?.email;
      this.getProductFeedback();
    } else if (phoneIdentity?.value) {
      this.userId = phoneIdentity.value;
      if (this.producerCode) {
        this.getProductFeedback();
      } else {
        getProducerCodeFromPhoneNumber(this.state.sqBaseUrl, this.userId)
          .subscribe({
            next: (producerCodeResult) => {
              if (producerCodeResult.producerCode) {
                this.producerCode = producerCodeResult.producerCode;
                this.getProductFeedback();
              }
            },
            error: () => {
              this.showProductFeedback = false;
            }
          });
      }
    }

    getFieldValue(
      'ticket.id'
    ).pipe(
      switchMap((id) => {
        this.ticketId = id;
        this.loadTicketData(id);
        return getAutomationLevel(id);
      }),
      tap((level) => {
        this.helpCenterRequestAutomationLevel = level;
      }),
      switchMap(() => {
        if (isHelpCenterRequest) {
          return getHelpCenterRequest(this.state.sqBaseUrl, this.ticketId);
        }

        return of(null);
      })
    ).subscribe(
      (hcRequest: any) => {
        // Note: always redirect on a ticket that was once in
        // review regardless of whether a BSA has acted on it
        if (hcRequest) {
          this.helpCenterRequestData = hcRequest;
          this.navigateToHelpCenterRequest();
        }
      }
    );
  },
  mounted() {
    getCurrentUser().subscribe((accountInfo: any) => {
      identifyUser(accountInfo);
      this.currentUser = accountInfo;
      this.loadUserOktaDetails();
    }, () => {
      Sentry.captureException(new Error('Failed to load user information'));
    });
  },
  methods: {
    getBranch() {
      return getBranch();
    },
    isLocal() {
      return process.env.NODE_ENV === 'local';
    },
    isNonProd() {
      return process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'staging';
    },
    handleSqChange() {
      localStorage.setItem(SQ_LOCALSTORAGE_KEY, this.state.sqBaseUrl);

      // Due to how we handle the HC request data/redirect to ticket view, it's easiest to just tell the user to refresh
      // TODO - make this component/data reactive so we reload the HC request data once the SQ URL is changed
      if (this.currentView === 'tickets') {
        this.needsPageRefresh = true;
      }
    },
    loadUserOktaDetails() {
      searchUsers(this.state.sqBaseUrl, this.currentUser.email).subscribe((oktaResults: any) => {
        const matchingProfile = oktaResults.find((oktaUser) => oktaUser.profile.email === this.currentUser.email);
        if (matchingProfile) {
          this.currentUserOktaInfo = matchingProfile;
        } else {
          this.oktaInfoNotFound = true;
        }
      }, () => {
        Sentry.captureException(new Error('Failed to load user information'));
      });
    },
    async loadTicketData(id: string) {
      const ticketData = await getTicketData(id);
      const ticketComments = await getTicketComments(id);
      const customFields: CustomField[] = ticketData.custom_fields || [];

      this.isProductFeedbackChannel = ['chat', 'voice'].includes(ticketData?.via?.channel);

      customFields.forEach((field: CustomField) => {
        if (field.value !== null) {
          this.customFieldValues[field.id] = field.value;
        }
      });

      const inboundInquiryType = this.customFieldValues[ZENDESK_FIELD_IDS.INBOUND_INQUIRY_TYPE];
      if (HCF_TICKET_DATA_VIEW_INQUIRY_TYPES.includes(inboundInquiryType)) {
        getFormStructure(this.state.sqBaseUrl, inboundInquiryType)
          .subscribe((formStructure) => {
            if (ticketData.via.channel === 'email') {
              this.showEmailFormView = true;
              this.hasAttachments = ticketComments[0].attachments.length > 0;
            } else {
              this.showHcfDataView = true;
            }

            this.formStructure = formStructure;
            this.formInboundInquiryType = inboundInquiryType;
          }, () => {
            Sentry.captureException(new Error('Failed to load form structure'));
          });
      }
    },
    resetView() {
      this.currentView = 'accounts';
    },
    navigateToHelpCenterRequest() {
      this.currentView = 'tickets';
    },
    getProductFeedback() {
      checkProductFeedback(
        this.state.sqBaseUrl,
        'attune_gw',
        'wc',
        this.producerCode,
        this.userId
      ).subscribe({
        next: (wcProductFeedback) => {
          this.showProductFeedback = wcProductFeedback.hasProductAccess && !wcProductFeedback.hasSubmittedFeedback;
        },
        error: () => {
          this.showProductFeedback = false;
        }
      });
    },
    submitNonRenewalNotice,
    submitRolloverPacket,
    submitNonPayNotice,
    submitUWCancellationNotice
  },
  computed: {
    hasProducerSearchPermission(): boolean {
      if (!this.currentUser) {
        return false;
      }
      if (process.env.NODE_ENV === 'production') {
        return hasProducerSearch(this.currentUser);
      }
      return true;
    },
    hasLobAccessPermission(): boolean {
      if (!this.currentUser) {
        return false;
      }
      return hasLobAccess(this.currentUser);
    },
    hasProductFeedbackPermission(): boolean {
      if (!this.currentUser) {
        return false;
      }
      return hasProductFeedback(this.currentUser);
    }
  }
});
