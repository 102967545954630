
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    collapsed: {
      type: Boolean,
      required: true
    },
    hideBorder: {
      type: Boolean,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  emits: ['toggleCollapse'],
  methods: {
    toggleCollapse() {
      this.$emit('toggleCollapse');
    }
  }
});
