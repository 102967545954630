/* eslint-disable */

// If you need to change these values for testing purposes,
// please do so in the appropriate environment branch below
let SQ_URL_BASE;
let AGENTPORTAL_URL_BASE;
let ZENDESK_URL_BASE;

interface ZendeskFieldIdSet {
  INSURED_NAME: string;
  INSURED_EMAIL: string;
  DBA: string;
  BUSINESS_MAILING_ADDRESS: string;
  ACCOUNT_NUMBER: string;
  LINE_OF_BUSINESS: string;
  POLICY_NUMBER: string;
  LATEST_INVOICE_LINK: string;
  AUTOMATION_TYPE: string;
  AUTOMATION_MANUAL_EXPLANATION: string;
  INBOUND_INQUIRY_TYPE: string;
}
interface ZendeskGroupIdSet {
  CANCELLATION:  number;
  REINSTATEMENT: number;
  COMMISSION: number;
  LOB: number;
}
let ZENDESK_FIELD_IDS: ZendeskFieldIdSet;
let ZENDESK_GROUP_IDS: ZendeskGroupIdSet;

if (process.env.NODE_ENV === 'production') {
  // These settings are for production
  SQ_URL_BASE = 'https://api.attuneinsurance.com';
  AGENTPORTAL_URL_BASE = 'https://app.attuneinsurance.com';
  ZENDESK_URL_BASE = 'https://attunehelp.zendesk.com/agent';

  ZENDESK_FIELD_IDS = {
    INSURED_NAME: '360030332412',
    INSURED_EMAIL: '360031987591',
    DBA: '360039228591',
    BUSINESS_MAILING_ADDRESS: '360039228571',
    ACCOUNT_NUMBER: '360001973232',
    LINE_OF_BUSINESS: '360028725831',
    POLICY_NUMBER: '360030415011',
    LATEST_INVOICE_LINK: '360042560991',
    AUTOMATION_TYPE: '4456111403803',
    AUTOMATION_MANUAL_EXPLANATION: '4513644525595',
    INBOUND_INQUIRY_TYPE: '360040534471'
  }
  ZENDESK_GROUP_IDS = {
    CANCELLATION: 1260815472609,
    REINSTATEMENT: 1260815472649,
    COMMISSION: 4411712754587,
    LOB: 6333466434203,
  }
} else {
  // These settings are for staging and local
  SQ_URL_BASE = 'https://api.attunestaging.com';
  AGENTPORTAL_URL_BASE = 'https://app.attunestaging.com';
  ZENDESK_URL_BASE = 'https://attunehelp1562077002.zendesk.com/agent';

  ZENDESK_FIELD_IDS = {
    INSURED_NAME: '360041650772',
    INSURED_EMAIL: '360031929912',
    DBA: '360040496252',
    BUSINESS_MAILING_ADDRESS: '360021491392',
    ACCOUNT_NUMBER: '360020406531',
    LINE_OF_BUSINESS: '360040456231',
    POLICY_NUMBER: '360020446231',
    LATEST_INVOICE_LINK: '360042561151',
    AUTOMATION_TYPE: '4456118633755',
    AUTOMATION_MANUAL_EXPLANATION: '4513651740571',
    INBOUND_INQUIRY_TYPE: '360040320671'
  };

  ZENDESK_GROUP_IDS = {
    CANCELLATION:  1260815483030,
    REINSTATEMENT: 1260815483050,
    COMMISSION: 4411712777243,
    LOB: 6407253560731,
  }
}

const getBranch = () => {
  return (window as any).branchName || '';
};

const SQ_LOCALSTORAGE_KEY = 'attune-zendesk-sq';
const SQ_PROVIDE_KEY = 'sqBaseUrl';

const SQ_URLS_NONPROD = [
  'https://api.attunestaging.com',
  'https://api.awitc.attunesandbox.com',
  'https://api.akamm.attunesandbox.com',
  'https://api.byank.attunesandbox.com',
  'https://api.eelda.attunesandbox.com',
  'https://api.jahuj.attunesandbox.com',
  'https://api.marg1.attunesandbox.com',
  'https://api.marg2.attunesandbox.com',
  'https://api.mgall.attunesandbox.com',
  'https://api.mmall.attunesandbox.com',
  'https://api.pcv10.attunesandbox.com',
  'https://api.rturn.attunesandbox.com',
  'https://api.sande.attunesandbox.com',
  'https://api.sshar.attunesandbox.com',
  'https://api.wcumb.attunesandbox.com',
  'https://api.zmoha.attunesandbox.com',
  'https://api.bcort.attunesandbox.com',
  'https://api.ahahn.attunesandbox.com',
  'https://api.apaul.attunesandbox.com',
  'https://api.srums.attunesandbox.com',
  'https://api.thels.attunesandbox.com',
] as const;
type SQ_URL = typeof SQ_URLS_NONPROD[number];

const SQ_URL_LABELS: Record<SQ_URL, string> = {
  'https://api.attunestaging.com': 'Staging',
  'https://api.awitc.attunesandbox.com': 'AWITC',
  'https://api.akamm.attunesandbox.com': 'AKAMM',
  'https://api.byank.attunesandbox.com': 'BYANK',
  'https://api.eelda.attunesandbox.com': 'EELDA',
  'https://api.jahuj.attunesandbox.com': 'JAHUJ',
  'https://api.marg1.attunesandbox.com': 'MARG1',
  'https://api.marg2.attunesandbox.com': 'MARG2',
  'https://api.mgall.attunesandbox.com': 'MGALL',
  'https://api.mmall.attunesandbox.com': 'MMALL',
  'https://api.pcv10.attunesandbox.com': 'PCV10',
  'https://api.rturn.attunesandbox.com': 'RTURN',
  'https://api.sande.attunesandbox.com': 'SANDE',
  'https://api.sshar.attunesandbox.com': 'SSHAR',
  'https://api.wcumb.attunesandbox.com': 'WCUMB',
  'https://api.zmoha.attunesandbox.com': 'ZMOHA',
  'https://api.bcort.attunesandbox.com': 'BCORT',
  'https://api.ahahn.attunesandbox.com': 'AHAHN',
  'https://api.apaul.attunesandbox.com': 'APAUL',
  'https://api.srums.attunesandbox.com': 'SRUMS',
  'https://api.thels.attunesandbox.com': 'THELS',
}

export {
  getBranch,
  ZendeskFieldIdSet,
  ZENDESK_URL_BASE,
  AGENTPORTAL_URL_BASE,
  SQ_URL_BASE,
  ZENDESK_FIELD_IDS,
  ZENDESK_GROUP_IDS,
  SQ_URLS_NONPROD,
  SQ_URL,
  SQ_URL_LABELS,
  SQ_LOCALSTORAGE_KEY,
  SQ_PROVIDE_KEY
}
