import { getPolicyListFlattened } from './ParseAccounts';

const getCancellationOptions = (accountInfo) => {
  const options = [];
  if (accountInfo.Policies?.Entry) {
    const policies: BoundPolicyInfo[] = getPolicyListFlattened(accountInfo);
    const inForcePolicies = policies.filter((policy) => policy.TermDisplayStatus_ATN === 'In Force');
    const scheduledPolicies = policies.filter((policy) => policy.TermDisplayStatus_ATN === 'Scheduled');

    if (inForcePolicies.length) {
      const inForceBop = inForcePolicies.find((policy) => policy.LineBusinessType === 'Businessowners Line (v7)');
      const inForceXs = inForcePolicies.find((policy) => policy.LineBusinessType === 'Commercial Excess Liability Line');

      const bopQuote = accountInfo.AllPoliciesSummary_HUSA.Entry.find(
        (quote) => quote.PolicyNumber === inForceBop.PolicyNumber
      );
      let bopType = 'BOP';
      if (bopQuote) {
        bopType = bopQuote.UWCompanyBlackBoard ? 'BB BOP' : 'BOP+';
      }

      if (!inForceXs) {
        options.push({
          policyNumber: inForceBop.PolicyNumber,
          label: `${inForceBop.PolicyNumber} (${bopType})`,
          lineOfBusiness: 'BOP'
        });
      } else {
        options.push({
          policyNumber: inForceBop.PolicyNumber,
          label: `${inForceBop.PolicyNumber} (${bopType}) <br> ${inForceXs.PolicyNumber} (XS)`,
          lineOfBusiness: 'BOP+XS'
        });
        options.push({
          policyNumber: inForceXs.PolicyNumber,
          label: `${inForceXs.PolicyNumber} (XS)`,
          lineOfBusiness: 'XS'
        });
      }
    }

    if (scheduledPolicies.length) {
      const scheduledBop = scheduledPolicies.find((policy) => policy.LineBusinessType === 'Businessowners Line (v7)');
      const scheduledXs = scheduledPolicies.find((policy) => policy.LineBusinessType === 'Commercial Excess Liability Line');
      const bopQuote = accountInfo.AllPoliciesSummary_HUSA.Entry.find(
        (quote) => quote.PolicyNumber === scheduledBop.PolicyNumber
      );
      let bopType = 'BOP';
      if (bopQuote) {
        bopType = bopQuote.UWCompanyBlackBoard ? 'BB BOP' : 'BOP+';
      }

      if (!scheduledXs) {
        options.push({
          policyNumber: scheduledBop.PolicyNumber,
          label: `${scheduledBop.PolicyNumber} (${bopType})`,
          lineOfBusiness: 'BOP'
        });
      } else {
        options.push({
          policyNumber: scheduledBop.PolicyNumber,
          label: `${scheduledBop.PolicyNumber} (${bopType}) <br> ${scheduledXs.PolicyNumber} (XS)`,
          lineOfBusiness: 'BOP+XS'
        });
        options.push({
          policyNumber: scheduledXs.PolicyNumber,
          label: `${scheduledXs.PolicyNumber} (XS)`,
          lineOfBusiness: 'XS'
        });
      }
    }
  }
  return options;
};

const refundMethods = ['prorata', 'flat', 'shortrate'] as const;
type RefundMethod = typeof refundMethods[number];

const cancelReasons = ['businessClosed', 'businessSold', 'nottaken', 'nofincononpay_HUSA', 'noc'] as const;
type CancelReason = typeof cancelReasons[number];

enum CancellationState {
  REVIEW = 'REVIEW',
  START = 'START',
  FINALIZE = 'FINALIZE',
  SUBMITTED = 'SUBMITTED',
  RESCINDED = 'RESCINDED',
}

const CANCEL_REASON_LABELS: Record<CancelReason, string> = {
  businessClosed: 'Business Closed',
  businessSold: 'Business Sold',
  nottaken: 'Policy Not-Taken',
  nofincononpay_HUSA: "Insured's request < or = 30 days of policy effective date (No Finance co)",
  noc: "Insured's request > 30 days of policy effective date (No Finance co)"
} as const;

const REFUND_METHOD_LABELS: Record<RefundMethod, string> = {
  prorata: 'Pro Rata',
  flat: 'Flat',
  shortrate: 'Short Rate'
} as const;

const CANCEL_REASON_TO_REFUND_METHOD: Record<CancelReason, RefundMethod> = {
  businessClosed: 'prorata',
  businessSold: 'prorata',
  nottaken: 'flat',
  nofincononpay_HUSA: 'shortrate',
  noc: 'shortrate'
} as const;

export {
  getCancellationOptions,
  refundMethods,
  RefundMethod,
  cancelReasons,
  CancelReason,
  CancellationState,
  CANCEL_REASON_LABELS,
  REFUND_METHOD_LABELS,
  CANCEL_REASON_TO_REFUND_METHOD
};
