import { from } from 'rxjs';
import { getJwt } from '../helpers/requests';
import client from './Client';

const getReinstatementInfo = (baseUrl: string, policyNumber: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/reinstatement-eligibility/${policyNumber}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

const createReinstatementInvoice = (baseUrl: string, policyNumber: string, userName: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/create-reinstatement-invoice`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify({
      policyNumber,
      userName
    })
  }));
};

const createReinstatement = (baseUrl: string, policyNumber: string, userName: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/create-reinstatement`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify({
      policyNumber,
      userName
    })
  }));
};

export {
  getReinstatementInfo,
  createReinstatementInvoice,
  createReinstatement
};
