
import { defineComponent, inject } from 'vue';
import moment from 'moment';
import { interval, merge, of as observableOf } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import * as FileSaver from 'file-saver';

import * as Sentry from '@sentry/vue';
import { SQ_PROVIDE_KEY } from '@/helpers/settings';
import { UserInfo } from '@/helpers/types';
import { trackEvent } from '../helpers/amplitude';
import {
  getCancellationOptions,
  cancelReasons,
  CancellationState,
  CANCEL_REASON_LABELS,
  REFUND_METHOD_LABELS,
  CANCEL_REASON_TO_REFUND_METHOD
} from '../helpers/cancellations';
import { hasCancellation } from '../helpers/flags';

import { cancelPolicy, withdrawCancellation } from '../requests/Accounts';
import { getPolicyListFlattened, getLatestAccountCancellation } from '../helpers/ParseAccounts';
import { checkPolicyDocument, downloadPolicyDocument } from '../requests/Documents';
import { addPrivateComment } from '../requests/TicketFields';

export default defineComponent({
  data() {
    return {
      state: CancellationState.REVIEW,
      submitting: false,
      processingWithdraw: false,
      hasRescindError: false,
      hasSubmitError: false,
      hasDocumentAvailable: false,
      cancellationJobNumber: '',
      isDownloading: false,
      hasDownloadError: false,
      awaitingCancel: false,
      // Consts which are used in the template, which must be copied into the component in order to be used
      cancelReasons,
      CANCEL_REASON_LABELS,
      REFUND_METHOD_LABELS,
      // Form inputs
      selectedPolicy: null,
      cancelReason: '',
      effectiveDate: '',
      confirmCancel: false
    };
  },

  props: {
    accountInfo: {
      type: Object,
      default: null
    },
    transactionData: {
      type: Object,
      default: null
    },
    currentUser: {
      type: Object
    },
    userOktaInfo: {
      type: Object
    }
  },

  setup() {
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);
    return { sqBaseUrl };
  },

  emits: ['account-cancel'],

  watch: {
    accountInfo(newValue) {
      if (this.awaitingCancel) {
        if (!newValue) {
          return;
        }

        this.awaitingCancel = false;
        const latestCancel = getLatestAccountCancellation(newValue);

        if (latestCancel && moment(latestCancel.Job.UpdateTime).isSame(moment(), 'day')) {
          this.state = CancellationState.SUBMITTED;
          this.beginDocumentPolling();
          addPrivateComment(
            `Cancelled policy ${this.selectedPolicy} on account ${this.accountInfo.AccountNumber}
          (cancel reason: ${this.cancelReason}. user: ${this.currentUser.email})`
          ).subscribe();
        } else {
          this.hasSubmitError = true;
        }
      }
    }
  },

  computed: {
    hasCancellationPermission(): boolean {
      if (this.currentUser) {
        return hasCancellation(this.currentUser as UserInfo);
      }
      return true;
    },
    address(): any {
      if (this.accountInfo) {
        return this.accountInfo.AccountHolderContact.PrimaryAddress;
      }
      return {};
    },
    hasPendingCancel(): boolean {
      const termPeriods = this?.transactionData?.PolicyPeriod?.PolicyTerm?.PortalViewableTermPeriods?.Entry;
      if (termPeriods) {
        return termPeriods.some(
          (termPeriod) => termPeriod.Status === 'Canceling'
        );
      }
      return false;
    },
    policyOptions(): any[] {
      return getCancellationOptions(this.accountInfo);
    },
    selectedPolicyQuote(): any {
      return getPolicyListFlattened(this.accountInfo).find(
        (quote: any) => quote.PolicyNumber === this.selectedPolicy
      );
    },
    cancellationIsDatedTooLate(): boolean {
      if (this.selectedPolicyQuote && this.effectiveDate) {
        const expiration = moment.utc(this.selectedPolicyQuote?.PeriodEnd);
        return moment.utc(this.effectiveDate).isSameOrAfter(expiration, 'day');
      }
      return false;
    },
    cancellationIsDatedTooEarly(): boolean {
      if (this.selectedPolicyQuote && this.effectiveDate) {
        const policyEffective = moment.utc(this.selectedPolicyQuote?.PeriodStart);
        return moment.utc(this.effectiveDate).isBefore(policyEffective, 'day');
      }
      return false;
    },
    hasXsSelected(): boolean {
      const policyDetails = this.policyOptions.find((option) => option.policyNumber === this.selectedPolicy);
      return !!policyDetails && policyDetails.lineOfBusiness.includes('XS');
    },
    policyLabel(): string {
      if (this.selectedPolicy) {
        const matchingPolicy = this.policyOptions.find((option) => option.policyNumber === this.selectedPolicy);
        return matchingPolicy ? matchingPolicy.label : '';
      } else {
        return '';
      }
    },
    refundMethod(): string {
      return CANCEL_REASON_TO_REFUND_METHOD[this.cancelReason];
    },
    cancelSource(): string {
      // All cancel reasons available in the app have this source
      return 'insured';
    },
    hasActiveXs(): boolean {
      return this.policyOptions.some((option) => option.lineOfBusiness.includes('XS'));
    },
    hasScheduledRenewal(): boolean {
      const policies = this.accountInfo?.Policies?.Entry[0]?.PortalViewableNewTermPeriods?.Entry;
      const numPolicies = policies.length;
      return numPolicies > 1 && policies.some(
        (policy) => policy.TermDisplayStatus_ATN === 'Scheduled'
      );
    },
    hasQuotedRenewal(): boolean {
      const policies = this.accountInfo?.Policies?.Entry[0]?.PortalViewableNewTermPeriods?.Entry;
      const numPolicies = policies.length;
      return numPolicies > 1 && policies.some(
        (policy) => policy.TermDisplayStatus_ATN === 'Renewing'
      );
    },
    hasExpiringPolicyWithin30Days(): boolean {
      const now = moment();
      const expiration = moment.utc(this.selectedPolicyQuote?.PeriodEnd);
      const daysUntilExpiry = expiration.diff(now, 'day');
      return daysUntilExpiry < 30;
    },
    hasExpiringPolicyWithin90Days(): boolean {
      const now = moment();
      const expiration = moment.utc(this.selectedPolicyQuote?.PeriodEnd);
      const daysUntilExpiry = expiration.diff(now, 'day');
      return daysUntilExpiry < 90;
    },
    displayScheduledRenewalWarning(): boolean {
      return this.hasScheduledRenewal && this.hasExpiringPolicyWithin30Days;
    },
    displayQuotedRenewalWarning(): boolean {
      return this.hasQuotedRenewal && this.hasExpiringPolicyWithin90Days;
    },
    displayRenewalWarning(): boolean {
      return this.displayScheduledRenewalWarning || this.displayQuotedRenewalWarning;
    },
    hasNotConfirmedRenewalCancellation(): boolean {
      return this.displayRenewalWarning && !this.confirmCancel;
    },
  },
  methods: {
    withdraw() {
      trackEvent('cancellation-withdraw', null, 'CancellationSection');
      this.processingWithdraw = true;

      const termPeriods = this?.transactionData?.PolicyPeriod?.PolicyTerm?.PortalViewableTermPeriods?.Entry || [];
      const cancellationJob = termPeriods.find((termPeriod) => termPeriod.Status === 'Canceling');
      const jobNumber = cancellationJob ? cancellationJob?.Job?.JobNumber : null;
      if (!jobNumber) {
        const config = {
          contexts: {
            metaData: {
              accountInfo: { accountNumber: this.accountInfo.Accountumber }
            }
          }
        };
        Sentry.captureException(new Error('Attempted to rescind, but could not find a job number'), config);
        this.processingWithdraw = false;
        this.hasRescindError = true;
        this.state = CancellationState.RESCINDED;
        return;
      }
      withdrawCancellation(this.sqBaseUrl, jobNumber, this.userOktaInfo.profile.gwUserName).subscribe(() => {
        this.processingWithdraw = false;
        this.state = CancellationState.RESCINDED;
        this.$emit('account-cancel');
      }, () => {
        this.processingWithdraw = false;
        this.hasRescindError = true;
        this.state = CancellationState.RESCINDED;
      });
    },
    handleReasonChange() {
      // For Policy Not-Taken cancels, the effective date is almost always the policy effective date
      if (this.cancelReason === 'nottaken') {
        if (this.selectedPolicy) {
          const policyQuote: any = getPolicyListFlattened(this.accountInfo).find(
            (quote: any) => quote.PolicyNumber === this.selectedPolicy
          );
          const policyEffective = policyQuote?.PeriodStart;
          this.effectiveDate = moment.utc(policyEffective).format('YYYY-MM-DD');
        }
      }
    },
    restartCancellation() {
      this.hasRescindError = false;
      this.state = CancellationState.REVIEW;
    },
    beginCancellation(retainFormState?: boolean) {
      trackEvent('cancellation-begin', null, 'CancellationSection');
      this.state = CancellationState.START;
      if (!retainFormState) {
        this.cancelReason = 'businessClosed';
        this.effectiveDate = moment().format('YYYY-MM-DD');
        if (this.policyOptions.length) {
          this.selectedPolicy = this.policyOptions[0].policyNumber;
        }
      }
    },
    finalizeCancellation() {
      trackEvent('cancellation-finalize', null, 'CancellationSection');
      this.state = CancellationState.FINALIZE;
    },
    downloadCancellationDocument() {
      if (this.isDownloading) {
        return;
      }
      this.isDownloading = true;
      this.hasDownloadError = false;
      downloadPolicyDocument(this.sqBaseUrl, this.cancellationJobNumber).subscribe((result) => {
        FileSaver.saveAs(result, `${this.accountInfo.AccountNumber}-cancellation-document.pdf`);
        this.isDownloading = false;
      }, () => {
        this.isDownloading = false;
        this.hasDownloadError = true;
      });
    },
    beginDocumentPolling() {
      if (!this.cancellationJobNumber) {
        Sentry.captureException(new Error('Tried to poll for cancel document, but there was no cancellation transaction'));
      }

      const fourSeconds = 4 * 1000;
      merge(
        observableOf(true),
        interval(fourSeconds)
      ).pipe(
        takeWhile(() => !this.hasDocumentAvailable),
        switchMap(() => {
          return checkPolicyDocument(this.sqBaseUrl, this.cancellationJobNumber);
        })
      ).subscribe((isAvailable) => {
        this.hasDocumentAvailable = isAvailable;
      });
    },
    submit() {
      const policyDetails = this.policyOptions.find((option) => option.policyNumber === this.selectedPolicy);
      const { lineOfBusiness } = policyDetails;
      trackEvent('cancel-policy', null, 'CancellationSection', { accountNumber: this.accountInfo.AccountNumber, lineOfBusiness });
      this.submitting = true;
      this.hasSubmitError = false;

      let cancelDate = this.effectiveDate;
      if (this.cancelReason === 'nottaken') {
        cancelDate = moment.utc(this.selectedPolicyQuote?.PeriodStart).format();
        if (!cancelDate) {
          this.hasSubmitError = true;
          Sentry.captureException(new Error('Failed to cancel; policy start could not be found'));
          return;
        }
      }
      cancelPolicy(
        this.sqBaseUrl,
        this.selectedPolicy,
        cancelDate,
        this.cancelSource,
        this.cancelReason,
        this.refundMethod,
        this.currentUser.email,
        this.userOktaInfo.profile.gwUserName
      ).subscribe((cancelResult: { transactionId: string }) => {
        this.cancellationJobNumber = cancelResult.transactionId;
        this.submitting = false;
        this.state = CancellationState.SUBMITTED;
        this.$emit('account-cancel');
        this.awaitingCancel = true;
      }, () => {
        // This error may be due to a timeout. In that case, reload and check to see if any cancel was created
        this.submitting = false;
        this.$emit('account-cancel');
        this.awaitingCancel = true;
      });
    }
  }
});
