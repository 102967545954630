import { from } from 'rxjs';
import { getJwt } from '../helpers/requests';
import client from './Client';

const getAccountDetails = (baseUrl: string, accountId: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/account/${accountId}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

const getAccountIssues = (baseUrl: string, accountId: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/account-issues/${accountId}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

const cancelPolicy = (
  baseUrl: string,
  policyNumber: string,
  cancellationDate: string,
  cancellationSource: string,
  reasonCode: string,
  refundCalcMethod: string,
  currentUser: string,
  gwUsername: string
) => {
  // Note: this description text is required by PC - do not change without consulting GW team.
  const description = 'Zendesk app cancellation';
  return from(client.request({
    data: JSON.stringify({
      policyNumber,
      cancellationDate,
      cancellationSource,
      reasonCode,
      refundCalcMethod,
      description,
      userName: gwUsername
    }),
    dataType: 'json',
    url: `${baseUrl}/v3/external/zd/cancel-policy`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'POST',
    contentType: 'application/json'
  }));
};

const withdrawCancellation = (
  baseUrl: string,
  jobNumber: string,
  gwUsername: string
) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/withdraw-cancel`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    data: JSON.stringify({
      jobNumber,
      userName: gwUsername
    }),
    secure: true,
    type: 'POST',
    contentType: 'application/json'
  }));
};

const getQuoteIssues = (baseUrl: string, jobNumber: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/quote-issues/${jobNumber}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

const getPolicyTransactions = (baseUrl: string, policyNumber: string, term: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/transactions/${policyNumber}/${term}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

const searchAccounts = (baseUrl: string, query: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/account-search/${query}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

export {
  cancelPolicy,
  getAccountDetails,
  getAccountIssues,
  getQuoteIssues,
  getPolicyTransactions,
  withdrawCancellation,
  searchAccounts
};
