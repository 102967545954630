
import { defineComponent, inject } from 'vue';
import * as FileSaver from 'file-saver';

import { downloadReplacementDoc } from '../requests/Documents';
import { AGENTPORTAL_URL_BASE, SQ_PROVIDE_KEY } from '../helpers/settings';
import { getQuoteIssues } from '../requests/Accounts';
import DownloadIcon from './DownloadIcon.vue';

export default defineComponent({
  data() {
    return {
      quoteIssues: null,
      hasReplacementDocError: false,
      quoteIssuesCollapsed: true,
      publicPath: process.env.BASE_URL,
    };
  },

  components: { DownloadIcon },

  props: {
    accountInfo: {
      type: Object,
      required: true
    },
    isRollover: {
      type: Boolean,
      required: true
    },
    quote: {
      type: Object,
      required: true
    },
    rolloverQuotes: {
      type: Array,
      required: true
    }
  },

  setup() {
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);
    return { sqBaseUrl };
  },

  created() {
    getQuoteIssues(this.sqBaseUrl, this.quote.quoteNumber).subscribe((quoteIssues) => {
      this.quoteIssues = quoteIssues;
    });
  },

  computed: {
    hasQuoteIssues(): boolean {
      return !!this?.quoteIssues?.Entry?.length;
    }
  },

  methods: {
    getQuoteLink(quote: any) {
      return quote.product === 'WC'
        ? `${AGENTPORTAL_URL_BASE}/accounts/${this.accountInfo.AccountNumber}/attune/workers-comp/quotes/${quote.quoteNumber}`
        : `${AGENTPORTAL_URL_BASE}/accounts/${this.accountInfo.AccountNumber}/bop/policies/${quote.quoteNumber}`;
    },
    downloadReplacementDoc() {
      this.hasReplacementDocError = false;
      downloadReplacementDoc(this.sqBaseUrl, this.accountInfo.AccountNumber).subscribe((result) => {
        FileSaver.saveAs(result, `${this.accountInfo.AccountNumber}-replacement-coverage-document.pdf`);
      }, () => {
        this.hasReplacementDocError = true;
      });
    },
    shouldHaveReplacementDoc(quote) {
      const rollovers: any[] = this.rolloverQuotes;
      if (rollovers.length > 1) {
        // Only show document download for the oldest of the rollover quotes
        return quote.quoteNumber === rollovers[rollovers.length - 1].quoteNumber;
      }
      return true;
    },
    showQuoteIssues() {
      this.quoteIssuesCollapsed = false;
    },
    hideQuoteIssues() {
      this.quoteIssuesCollapsed = true;
    }
  }
});
