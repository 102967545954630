import { from } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { getJwt } from '../helpers/requests';
import client from './Client';

const getDocumentsList = (baseUrl: string, policyNumber: string, termNumber: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/documents/zd/policy/${policyNumber}/${termNumber}`,
    headers: { 'X-Auth-Token': `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

const downloadDocument = (baseUrl: string, policyNumber: string, termNumber: string, type: string) => {
  return from(
    fetch(
      `${baseUrl}/v3/documents/zd/policy/${policyNumber}/${termNumber}/${type}`,
      {
        method: 'GET',
        headers: { 'X-Auth-Token': `Bearer ${getJwt()}`, Authorization: '', Accept: 'application/pdf' },
      }
    )
  ).pipe(
    map((resp) => {
      if (resp.status >= 400) {
        throw Error('Failed to download document');
      }
      return resp;
    }),
    switchMap((resp) => from(resp.blob()))
  );
};

const downloadAttuneStatement = (baseUrl: string, producerCode: string, statementNumber: string) => {
  return from(
    fetch(
      `${baseUrl}/v3/documents/zd/get-commission-statement/${producerCode}/${statementNumber}`,
      {
        method: 'GET',
        headers: { 'X-Auth-Token': `Bearer ${getJwt()}`, Authorization: '', Accept: 'application/pdf' },
      }
    )
  ).pipe(
    map((resp) => {
      if (resp.status >= 400) {
        throw Error('Failed to download document');
      }
      return resp;
    }),
    switchMap((resp) => from(resp.blob()))
  );
};

const downloadPartnerStatement = (baseUrl: string, producerCode: string, statementDate: string) => {
  return from(
    fetch(
      `${baseUrl}/v3/external/zd/producer-code/${producerCode}/commissions/statements/${encodeURIComponent(statementDate)}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getJwt()}`,
          Accept: 'application/pdf'
        },
      }
    )
  ).pipe(
    map((resp) => {
      if (resp.status >= 400) {
        throw Error('Failed to download document');
      }
      return resp;
    }),
    switchMap((resp) => from(resp.blob()))
  );
};

const checkPolicyDocument = (baseUrl: string, jobNumber: string) => {
  return from(
    fetch(
      `${baseUrl}/v3/documents/zd/get-policy-document/${jobNumber}`,
      {
        method: 'HEAD',
        headers: { 'X-Auth-Token': `Bearer ${getJwt()}`, Authorization: '', Accept: 'application/pdf' },
      }
    )
  ).pipe(
    map((resp) => {
      // Any other status (including 202) indicates that the document is not yet available for download
      return resp.status === 200;
    })
  );
};

const downloadPolicyDocument = (baseUrl: string, jobNumber: string) => {
  return from(
    fetch(
      `${baseUrl}/v3/documents/zd/get-policy-document/${jobNumber}`,
      {
        method: 'GET',
        headers: { 'X-Auth-Token': `Bearer ${getJwt()}`, Authorization: '', Accept: 'application/pdf' },
      }
    )
  ).pipe(
    map((resp) => {
      if (resp.status >= 400) {
        throw Error('Failed to download document');
      }
      return resp;
    }),
    switchMap((resp) => from(resp.blob()))
  );
};

const downloadReplacementDoc = (baseUrl: string, accountNumber: string) => {
  return from(
    fetch(
      `${baseUrl}/v3/documents/zd/coverage-replacement/${accountNumber}`,
      {
        method: 'GET',
        headers: { 'X-Auth-Token': `Bearer ${getJwt()}`, Authorization: '', Accept: 'application/pdf' },
      }
    )
  ).pipe(
    map((resp) => {
      if (resp.status >= 400) {
        throw Error('Failed to download document');
      }
      return resp;
    }),
    switchMap((resp) => from(resp.blob()))
  );
};

export {
  getDocumentsList,
  checkPolicyDocument,
  downloadDocument,
  downloadReplacementDoc,
  downloadPolicyDocument,
  downloadAttuneStatement,
  downloadPartnerStatement,
};
