
import { defineComponent, inject, ref } from 'vue';
import { SQ_PROVIDE_KEY } from '@/helpers/settings';
import { searchProducers, getProducerCodeHierarchy } from '../requests/Producers';
import { searchPayees, searchFinanceAdmins, getCommissionUser } from '../requests/Billing';
import { trackEvent } from '../helpers/amplitude';
import { getCommPlanReadable, getTipaltiStatus, hasPayeeSetup, isNoCommissionPlan } from '../helpers/billing';
import CommissionTable from './CommissionTable.vue';
import manageSubscriptions from '../helpers/manageSubscriptions';

export default defineComponent({
  components: {
    CommissionTable
  },

  setup() {
    const searchInput = ref(null);
    const { addSubscription } = manageSubscriptions();
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);

    return {
      searchInput,
      addSubscription,
      sqBaseUrl
    };
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      producerSearchString: '',
      hasProducerError: false,
      hasPayeeError: false,
      hasFinanceAdminError: false,
      hasProducerHierarchyError: false,
      hasProducerParent: false,
      hasSecondaryCode: false,
      hasReferrerCode: false,
      receivesNoCommission: false,
      hasCommissionUserError: false,
      debounceTimeout: null,
      producerInfo: null,
      payeeInfo: null,
      commissionUserInfo: null,
      financeAdminInfo: null,
      producerHierarchyInfo: null
    };
  },

  methods: {
    navigateToProducer(producerCode: string) {
      this.searchInput.value = producerCode;
      this.producerSearchString = producerCode;
      this.loadProducer();
    },

    updateSearchString(event: any) {
      if (this.producerSearchString === event.target.value) {
        return;
      }

      trackEvent('producer_search', event.target.value, 'ProducerSearch');
      this.producerSearchString = event.target.value;
      if (this.producerSearchString.length >= 4) {
        this.loadProducer();
      }
    },

    clearSearch() {
      this.searchInput.value = '';
      this.producerSearchString = '';
      this.loadProducer();
    },

    loadProducer() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        // Note: Producer codes should be capitalized, agency name search is case-insensitive
        const producerSearch = this.producerSearchString.toUpperCase();
        this.hasProducerError = false;
        this.hasPayeeError = false;
        this.hasFinanceAdminError = false;
        this.hasProducerHierarchyError = false;
        this.hasProducerParent = false;
        this.receivesNoCommission = false;
        this.producerInfo = null;
        this.payeeInfo = null;
        this.financeAdminInfo = null;
        this.producerHierarchyInfo = null;
        this.commissionUserInfo = null;

        if (!producerSearch) {
          return;
        }
        this.addSubscription(
          searchProducers(this.sqBaseUrl, producerSearch).subscribe(
            (producerInfo: any) => {
              this.producerInfo = producerInfo;
              const producerCode = producerInfo.ProducerCode;
              this.getProducerCodeHierarchy(producerCode);
              this.addSubscription(
                searchPayees(this.sqBaseUrl, producerCode).subscribe(
                  (payeeInfo) => {
                    this.payeeInfo = payeeInfo;
                  },
                  () => {
                    this.hasPayeeError = true;
                  }
                )
              );
              this.addSubscription(
                searchFinanceAdmins(this.sqBaseUrl, producerCode).subscribe(
                  (financeAdminInfo) => {
                    this.financeAdminInfo = financeAdminInfo;
                  },
                  () => {
                    this.hasFinanceAdminError = true;
                  }
                )
              );
              this.addSubscription(
                getCommissionUser(this.sqBaseUrl, producerCode).subscribe(
                  (commissionUserInfo) => {
                    this.commissionUserInfo = commissionUserInfo;
                  },
                  () => {
                    this.hasCommissionUserError = true;
                  }
                )
              );
            },
            () => {
              this.hasProducerError = true;
            }
          )
        );
      }, 1000);
    },

    getProducerCodeHierarchy(producerCode: string) {
      this.addSubscription(
        getProducerCodeHierarchy(this.sqBaseUrl, producerCode).subscribe(
          (producerHierarchyInfo: any) => {
            this.producerHierarchyInfo = producerHierarchyInfo;
            this.hasSecondaryCode = producerHierarchyInfo.SecondaryCode && producerHierarchyInfo.SecondaryCode !== producerCode;
            this.hasReferrerCode = producerHierarchyInfo.ReferrerCode && producerHierarchyInfo.ReferrerCode !== producerCode;
            this.hasProducerParent = this.hasSecondaryCode || this.hasReferrerCode;
            this.receivesNoCommission = isNoCommissionPlan(this.producerInfo.CommissionPlan) && this.hasProducerParent;
          },
          () => {
            this.hasProducerHierarchyError = true;
          }
        )
      );
    },

    getFinanceAdminsReadable(financeAdminInfo) {
      const financeAdminNamesReadable = financeAdminInfo.map(
        (info) => {
          if (!info.first_name || !info.last_name) {
            return `Finance Admin <${info.login}>`;
          }
          return `${info.first_name} ${info.last_name} <${info.login}>`;
        }
      );
      return financeAdminInfo.length ? financeAdminNamesReadable.join(', ') : 'None';
    },

    getCommPlanReadable(commPlanCode: string) {
      return getCommPlanReadable(commPlanCode);
    },

    getAddressReadable(producerInfo) {
      const streetString = producerInfo.Street || '(Street unavailable)';
      const cityString = producerInfo.City || '(City unavailable)';
      const stateString = producerInfo.State || '(State unavailable)';
      const zipString = producerInfo.Zip || '(Zip unavailable)';
      return `${streetString}, ${cityString}, ${stateString}, ${zipString}`;
    },

    getCommissionUserReadable(commissionUserInfo) {
      let resultString = '';
      const email1String = commissionUserInfo.CommissionUserEmail1;
      const email2String = commissionUserInfo.CommissionUserEmail2;

      // Determine what to show in app based on the values of email1 and email2
      if (!email1String && !email2String) {
        resultString = 'N/A';
      } else if (!email2String) {
        resultString = commissionUserInfo.CommissionUserEmail1;
      } else if (!email1String) {
        resultString = commissionUserInfo.CommissionUserEmail2;
      } else {
        resultString = `${commissionUserInfo.CommissionUserEmail1}, ${commissionUserInfo.CommissionUserEmail2}`;
      }

      return resultString;
    },

    getTipaltiStatus(payeeInfo) {
      return getTipaltiStatus(payeeInfo);
    },

    hasPayeeSetup(payeeInfo) {
      return hasPayeeSetup(payeeInfo);
    }
  }
});
