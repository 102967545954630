
import { defineComponent, inject } from 'vue';

import filter from 'lodash/filter';
import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import identity from 'lodash/identity';

import * as Sentry from '@sentry/vue';
import { addTag, setFieldValuesIfEmpty } from '../requests/TicketFields';

import { ZENDESK_FIELD_IDS, ZendeskFieldIdSet, SQ_PROVIDE_KEY } from '../helpers/settings';
import { getInvoiceDetails } from '../requests/Billing';
import { generateInvoiceLink } from '../helpers/invoices';
import manageSubscriptions from '../helpers/manageSubscriptions';
import { trackEvent } from '../helpers/amplitude';

enum FormLineOfBusiness {
  BOP = 'bop',
  BOP_PLUS = 'businessowners____excess__accredited__i.e._bop__',
  ATTUNE_WC = 'attune_work_comp',
  WC = 'wc',
  GL = 'general_liability',
  PL = 'professional_liability',
  NONE = '',
}

const ATTUNE_WC_UW_COMPANY_CODES = ['11240', '16632'];

export default defineComponent({
  props: {
    accountInfo: {
      type: Object,
      default: null
    },
    currentUser: {
      type: Object
    },
  },
  data() {
    return {
      invoiceInfo: null,
      isLoading: false,
      isLoadingBilling: false,
      hasBillingError: false,
      hasError: false,
      hasSuccess: false,
      selectedPolicyNumber: ''
    };
  },
  setup() {
    const { addNamedSubscription, removeNamedSubscription } = manageSubscriptions();
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);

    return {
      addNamedSubscription,
      removeNamedSubscription,
      sqBaseUrl
    };
  },
  created() {
    this.fetchInvoiceDetails();
  },
  watch: {
    accountInfo() {
      this.fetchInvoiceDetails();
    },
    policyNumbers: {
      immediate: true,
      handler(val) {
        if (val && val.length === 1) {
          this.selectedPolicyNumber = val[0];
        } else {
          this.selectedPolicyNumber = '';
        }
      }
    }
  },
  methods: {
    fetchInvoiceDetails() {
      this.removeNamedSubscription('billing');
      this.invoiceInfo = null;

      if (this.accountInfo) {
        this.isLoadingBilling = true;

        this.addNamedSubscription(
          'billing',
          getInvoiceDetails(
            this.sqBaseUrl,
            this.accountInfo.AccountNumber,
            this.accountInfo?.ProducerCodes?.Entry[0]?.ProducerCode?.Code
          ).subscribe((accountInfo: any) => {
            this.isLoadingBilling = false;
            this.invoiceInfo = accountInfo;
          }, () => {
            this.isLoadingBilling = false;
            this.hasBillingError = true;
          })
        );
      }
    },

    getLineOfBusiness(): FormLineOfBusiness {
      const hasGwQuote = !!this.accountInfo?.AllPoliciesSummary_HUSA?.Entry?.length;
      const hasBopQuote = hasGwQuote && this.accountInfo.AllPoliciesSummary_HUSA.Entry.some(
        (policy) => policy.UWCompanyBlackBoard
      );
      const hasBopPlus = hasGwQuote && this.accountInfo.AllPoliciesSummary_HUSA.Entry.some(
        (policy) => policy.UWCompanyAccredited
      );
      const hasAttuneWc = hasGwQuote && this.accountInfo.AllPoliciesSummary_HUSA.Entry.some(
        (policy) => ATTUNE_WC_UW_COMPANY_CODES.includes(policy.UWCompanyCode)
      );
      const { hasGlQuote, hasPlQuote, hasWcQuote } = this.accountInfo;
      const productCount = filter([hasAttuneWc, (hasBopPlus || hasBopQuote), hasGlQuote, hasPlQuote, hasWcQuote], identity).length;
      if (productCount !== 1) {
        return FormLineOfBusiness.NONE;
      } else if (hasAttuneWc) {
        return FormLineOfBusiness.ATTUNE_WC;
      } else if (hasBopPlus) {
        return FormLineOfBusiness.BOP_PLUS;
      } else if (hasBopQuote) {
        return FormLineOfBusiness.BOP;
      } else if (hasGlQuote) {
        return FormLineOfBusiness.GL;
      } else if (hasPlQuote) {
        return FormLineOfBusiness.PL;
      } else if (hasWcQuote) {
        return FormLineOfBusiness.WC;
      }
      return FormLineOfBusiness.NONE;
    },

    autofillTicketFields() {
      const lineOfBusiness = this.getLineOfBusiness();

      const fieldsToUpdate: Partial<ZendeskFieldIdSet> = {
        INSURED_NAME: this.accountInfo.AccountHolderContact.Name,
        INSURED_EMAIL: this.accountInfo.AccountHolderContact.EmailAddress1,
        ACCOUNT_NUMBER: this.accountInfo.AccountNumber,
        LINE_OF_BUSINESS: lineOfBusiness,
        POLICY_NUMBER:
          ([
            FormLineOfBusiness.BOP,
            FormLineOfBusiness.BOP_PLUS,
            FormLineOfBusiness.ATTUNE_WC,
          ].includes(lineOfBusiness)) ? this.selectedPolicyNumber : ''
      };

      if (this.invoiceInfo?.invoices?.length) {
        const { invoices } = this.invoiceInfo;
        const presentInvoicesSorted = invoices
          .filter((inv: any) => inv.status !== 'Planned')
          .sort((a, b) => (a.dueDate > b.dueDate ? -1 : 1));

        if (presentInvoicesSorted.length > 0) {
          fieldsToUpdate.LATEST_INVOICE_LINK = generateInvoiceLink(presentInvoicesSorted[0]);
        }
      }

      const fieldIdsToUpdate = mapKeys(fieldsToUpdate, (_value, key) => ZENDESK_FIELD_IDS[key]);

      // Fields to update, with sensitive fields omitted (so that 3rd party services do not receive them)
      const printableFieldsToUpdate = omit(fieldsToUpdate, ['LATEST_INVOICE_LINK']);

      trackEvent(
        'autofill_ticket_fields',
        null,
        'AutofillSection',
        printableFieldsToUpdate
      );

      this.addNamedSubscription(
        'updateFields',
        setFieldValuesIfEmpty(fieldIdsToUpdate).subscribe(
          () => {
            this.isLoading = false;
            this.hasSuccess = true;

            addTag('autofill').subscribe();
          },
          (err: Error) => {
            const config = {
              contexts: {
                metaData: {
                  updateInfo: {
                    fields: JSON.stringify(printableFieldsToUpdate),
                    message: err.message
                  }
                }
              }
            };
            Sentry.captureException(new Error('Failed to update ticket fields'), config);
            this.isLoading = false;
            this.hasError = true;
          }
        )
      );
    }
  },
  computed: {
    policyNumbers(): string[] {
      const policies = this.accountInfo?.Policies?.Entry;
      if (policies && policies.length) {
        const termPolicyNumbers: string[][] = policies.map(
          (policy) => policy.PortalViewableNewTermPeriods.Entry.map((period) => period.PolicyNumber)
        );
        // Remove duplicates, sort, and remove any empty strings
        return uniq(flatten(termPolicyNumbers)).filter(identity);
      }
      return [];
    }
  }
});

