import { from } from 'rxjs';
import { ZendeskTicketComment } from '@/helpers/types';

import client from './Client';

const getTicketComments = async (ticketId: string): Promise<ZendeskTicketComment[]> => {
  const response: any = await from(client.request({
    url: `/api/v2/tickets/${ticketId}/comments.json`,
    type: 'GET',
    contentType: 'application/json'
  })).toPromise();

  return response.comments;
};

export {
  getTicketComments
};
