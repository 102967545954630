export const MAX_FILES = 50;

export enum DndFileStatus {
  READY = 0,
  SUCCESS = 1,
  INVALID_INPUT = 2,
  ERROR = 3,
}

export interface DndFileEntry {
  file: File;
  status: DndFileStatus;
  errorMessage?: string;
}

export interface DndFileList {
  items: DndFileEntry[];
  allowedTypes?: string[];
}

export function addFiles(dndFileList: DndFileList, list: FileList) {
  const newFiles: DndFileEntry[] = [];

  for (let i = 0; i < list.length; i += 1) {
    const newFile: DndFileEntry = {
      file: list.item(i),
      status: DndFileStatus.READY
    };

    const isValidFile = !dndFileList.allowedTypes ||
       dndFileList.allowedTypes.some((fileExt) => (newFile.file.name.toLowerCase().endsWith(`.${fileExt.toLowerCase()}`)));

    if (!isValidFile) {
      newFile.status = DndFileStatus.INVALID_INPUT;
      newFile.errorMessage = 'Invalid file type';
    }

    const isFileNameDuplicate = dndFileList.items.some((entry) => entry.file.name === newFile.file.name);

    if (isFileNameDuplicate) {
      newFile.status = DndFileStatus.INVALID_INPUT;
      newFile.errorMessage = 'Cannot upload duplicate file';
    }

    newFiles.push(newFile);
  }

  const newFileList: DndFileList = { ...dndFileList };

  newFileList.items = [...dndFileList.items, ...newFiles];
  newFileList.items.sort((a, b) => a.file.name.localeCompare(b.file.name));

  return newFileList;
}

export function removeFile(dndFileList: DndFileList, entry: DndFileEntry) {
  const index = dndFileList.items.findIndex((item: DndFileEntry) => {
    return item.file === entry.file;
  });

  const newFileList: DndFileList = { ...dndFileList };

  newFileList.items = [...dndFileList.items.slice(0, index), ...dndFileList.items.slice(index + 1)];

  return newFileList;
}
