import { Subscription } from 'rxjs';
import { onBeforeUnmount } from 'vue';

export default function manageSubscriptions() {
  const subscriptions: Subscription[] = [];
  const namedSubscriptions: Record<string, Subscription> = {};

  const addSubscription = (subscription: Subscription) => {
    subscriptions.push(subscription);
  };

  const addNamedSubscription = (name: string, subscription: Subscription) => {
    if (namedSubscriptions[name]) {
      namedSubscriptions[name].unsubscribe();
    }
    namedSubscriptions[name] = subscription;
  };

  const removeNamedSubscription = (name: string) => {
    if (namedSubscriptions[name]) {
      namedSubscriptions[name].unsubscribe();
      namedSubscriptions[name] = undefined;
    }
  };

  onBeforeUnmount(() => {
    subscriptions.forEach((subscription) => subscription.unsubscribe());
    Object.values(namedSubscriptions).forEach((subscription) => subscription.unsubscribe());
  });

  return {
    addSubscription,
    addNamedSubscription,
    removeNamedSubscription
  };
}
