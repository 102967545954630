import { createApp } from 'vue';
import 'regenerator-runtime/runtime';

import * as Sentry from '@sentry/vue';
import amplitude from 'amplitude-js';

import App from './App.vue';

amplitude.getInstance().init('39b7dee9b59bde75f743e654faa6409c');

const app = createApp(App);

Sentry.init({
  app,
  debug: process.env.NODE_ENV !== 'production',
  dsn: 'https://a0e2eb704482a52e15159a5398d0ac9f@o1408929.ingest.sentry.io/4506310095470592',
  enabled: ['production', 'staging', 'local'].includes(process.env.NODE_ENV),
  environment: process.env.NODE_ENV,
  tracesSampleRate: 0.01,
  autoSessionTracking: true,
});

app.mount('#app');
