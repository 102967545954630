
import { defineComponent } from 'vue';
import find from 'lodash/find';
import { AddressFields, FieldType, ZendeskTicketField, ZendeskValueType } from '@/helpers/types';
import { trackEvent } from '@/helpers/amplitude';

export default defineComponent({
  props: {
    customFieldValues: {
      type: Object
    },
    formStructure: {
      type: Object
    },
    inboundInquiryType: {
      type: String
    }
  },
  data() {
    return {
      topLevelFieldOrder: [],
      formFieldOrder: []
    };
  },
  watch: {
    formStructure() {
      this.generateFieldOrder();
    }
  },
  methods: {
    generateFieldOrder() {
      const topLevelFieldOrder = [];

      this.formStructure.inquiryForms.formValues.forEach((customField) => {
        if (this.customFieldValues[customField.fieldId]) {
          const zendeskTicketField = find(this.formStructure.ticketFields, (ticketField) => {
            return ticketField.id === customField.fieldId;
          });
          const value = this.getReadableValue(zendeskTicketField, this.customFieldValues[customField.fieldId]);
          topLevelFieldOrder.push({
            fieldId: customField.fieldId,
            fieldLabel: customField.fieldLabel,
            fieldValue: value
          });
        }
      });
      this.topLevelFieldOrder = topLevelFieldOrder;

      // TODO consider refactoring generateFieldOrderHelper so it doesn't modify the input
      const formFieldOrder = [];
      this.populateFormFieldOrder(formFieldOrder, this.formStructure.inquiryForms.forms[this.inboundInquiryType].formValues);
      this.formFieldOrder = formFieldOrder;
    },
    populateFormFieldOrder(fieldOrder, formStructure) {
      formStructure.forEach((field) => {
        if (field.fieldType === FieldType.ADDRESS) {
          // Address nodes do not have any children so no need to recurse
          // If we have an address line 1 value, we can assume the rest of the address is filled out
          if (this.customFieldValues[field.addressFields?.line1]) {
            const addressValue = this.getReadableAddress(field.addressFields);
            fieldOrder.push({
              fieldId: field.fieldId,
              fieldLabel: field.fieldLabel,
              fieldValue: addressValue
            });
          }
        } else if (field.fieldId && this.customFieldValues[field.fieldId]) {
          const zendeskTicketField = find(this.formStructure.ticketFields, (ticketField) => {
            return ticketField.id === field.fieldId;
          });
          const value = this.getReadableValue(zendeskTicketField, this.customFieldValues[field.fieldId]);
          fieldOrder.push({
            fieldId: field.fieldId,
            fieldLabel: field.fieldLabel,
            fieldValue: value
          });

          if (field.fieldType === 'SELECT') {
            const childAnswer = find(field.children, (answerField) => {
              return answerField.answerValue === this.customFieldValues[field.fieldId];
            });

            if (!childAnswer) {
              trackEvent('zd-ticket-data-unknown-answer', this.customFieldValues[field.fieldId], 'TicketDataSection', {
                inquiryType: this.inboundInquiryType,
                fieldId: field.fieldId,
                answers: field.children.map((answerField) => {
                  return answerField.answerValue;
                })
              });
            }

            this.populateFormFieldOrder(fieldOrder, childAnswer?.children || []);
          } else if (field.children) {
            this.populateFormFieldOrder(fieldOrder, field.children);
          }
        }
      });
    },
    getReadableValue(zendeskTicketField: ZendeskTicketField, value) {
      switch (zendeskTicketField.type) {
        case ZendeskValueType.TAGGER: {
          const optionLabel = find(zendeskTicketField.custom_field_options, (option) => {
            return option.value === value;
          })?.name;
          return optionLabel || value;
        }
        case ZendeskValueType.MULTISELECT: {
          const optionLabels = [];
          zendeskTicketField.custom_field_options.forEach((option) => {
            if (value.includes(option.value)) {
              optionLabels.push(option.name);
            }
          });
          return optionLabels.join(', ') || value;
        }
        default:
          // For fields that don't require special handling (ex. text), just return the value
          return value;
      }
    },
    getReadableAddress(addressFields: AddressFields) {
      // TODO revisit this formatting
      const line1 = this.customFieldValues[addressFields.line1] || '';
      const line2 = this.customFieldValues[addressFields.line2] || '';
      const city = this.customFieldValues[addressFields.city] || '';
      const state = this.customFieldValues[addressFields.state] || '';
      const zip = this.customFieldValues[addressFields.zip] || '';

      return `${line1} ${line2} ${city}, ${state} ${zip}`;
    }
  }
});
