
import moment from 'moment';
import { defineComponent, inject, ref } from 'vue';
import { SQ_PROVIDE_KEY } from '@/helpers/settings';
import { searchUsers, getUserGroups } from '../requests/Users';

import { trackEvent } from '../helpers/amplitude';

export default defineComponent({
  beforeUnmount() {
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }
  },

  setup() {
    const searchInput = ref(null);
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);

    return { searchInput, sqBaseUrl };
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      userSearchString: '',
      hasUsersError: false,
      hasGroupsError: false,
      selectedAccount: null,
      debounceTimeout: null,
      usersInfo: null,
      selectedUser: null,
      userGroupInfo: null,
      usersSubscription: null,
      userGroupSubscription: null
    };
  },

  computed: {
    userGroupText(): string {
      let groups = this.userGroupInfo?.userGroups || [];
      groups = groups.filter((group) => group !== 'Everyone');
      return groups.length ? groups.join(', ') : 'None';
    },
    financeAdminList(): string {
      const groups = this.userGroupInfo?.producerGroups || [];
      const users = groups.filter((item) => item.groups.includes('finance-admin'));
      const userNames = users.map((item) => item.login);
      return userNames.length ? userNames.join(', ') : 'None';
    },
    userAdminList(): string {
      const groups = this.userGroupInfo?.producerGroups || [];
      const users = groups.filter((item) => item.groups.includes('user-admin'));
      const userNames = users.map((item) => item.login);
      return userNames.length ? userNames.join(', ') : 'None';
    }
  },

  methods: {
    clearSearch() {
      this.searchInput.value = '';
      this.userSearchString = '';
      this.loadUsers();
    },

    updateSearchString(event: any) {
      if (this.userSearchString === event.target.value) {
        return;
      }

      trackEvent('user_search', event.target.value, 'UserSearch');
      this.userSearchString = event.target.value;
      if (this.userSearchString.length >= 4) {
        this.loadUsers();
      }
    },

    getLoginText(loginField) {
      return loginField ? this.formatDate(loginField) : 'Never';
    },

    formatDate(dateString) {
      return moment(dateString).format('MMMM Do YYYY, h:mm:ss a');
    },

    showUserGroupInfo(user) {
      trackEvent('expand_user_groups', user.id, 'UserSearch');
      this.selectedUser = user.id;
      this.hasGroupsError = false;
      this.userGroupInfo = null;

      if (this.userGroupSubscription) {
        this.userGroupSubscription.unsubscribe();
      }

      this.userGroupSubscription = getUserGroups(this.sqBaseUrl, user.profile.email).subscribe((groupInfo) => {
        this.userGroupInfo = groupInfo;
      }, () => {
        this.hasGroupsError = true;
      });
    },

    hideUserGroupInfo() {
      this.selectedUser = null;
      this.userGroupInfo = null;
    },

    loadUsers() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        const currentQuery = this.userSearchString;
        this.hasUsersError = false;
        this.usersInfo = null;

        if (this.usersSubscription) {
          this.usersSubscription.unsubscribe();
        }

        if (!currentQuery) {
          return;
        }
        this.usersSubscription = searchUsers(this.sqBaseUrl, currentQuery).subscribe(
          (usersInfo) => {
            this.usersInfo = usersInfo;
          },
          () => {
            this.hasUsersError = true;
          }
        );
      }, 1500);
    }
  }
});
