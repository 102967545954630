import amplitude from 'amplitude-js';

const identifyUser = (userInfo) => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('Identifying user for Amplitude: ', userInfo.email);
  } else {
    amplitude.getInstance().setUserId(userInfo.email);
  }
};

const trackEvent = (
  eventName: string,
  value: string | null,
  componentName: string,
  additionalInfo: Record<string, string> = {}
) => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }
  const eventPayload = { value, componentName, environment: process.env.NODE_ENV, ...additionalInfo };

  if (process.env.NODE_ENV !== 'production') {
    console.log('Tracking Amplitude event: ', eventName, eventPayload);
  } else {
    amplitude.getInstance().logEvent(
      eventName,
      eventPayload
    );
  }
};

export {
  identifyUser,
  trackEvent
};
