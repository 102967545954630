import { Observable, from } from 'rxjs';
import { getJwt } from '../helpers/requests';
import client from './Client';

const searchProducers = (baseUrl: string, producerCode: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/producer-search/${producerCode}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

const getProducerFeatureState = (baseUrl: string, producerCode: string, flag: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/get-feature-flag?producer=${producerCode}&flag=${flag}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  })) as Observable<{ enabled: boolean | null }>;
};

const getProducerCodeHierarchy = (baseUrl: string, producerCode: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/producer-hierarchy/${producerCode}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

const getProducerContacts = (baseUrl: string, producerCode: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/producer-contacts/${producerCode}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

const getProducerCodeFromPhoneNumber = (baseUrl: string, phoneNumber: string): Observable<{ producerCode: string }> => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/find-producer-code/${phoneNumber}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  })) as Observable<{ producerCode: string}>;
};

interface NewContact {
  EmailAddress1: string;
  FirstName: string;
  LastName: string;
}

const createProducerContact = (baseUrl: string, producerCode: string, contactInfo: NewContact) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/producer-contacts/`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify({
      producerCode,
      contact: { ...contactInfo, Roles: ['CommissionContact'] }
    })
  }));
};

interface UpdatedContact {
  EmailAddress1?: string;
  FirstName?: string;
  LastName?: string;
  PublicID: string;
}

const updateProducerContact = (baseUrl: string, producerCode: string, contactInfo: UpdatedContact) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/producer-contacts/`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'PATCH',
    contentType: 'application/json',
    data: JSON.stringify({
      producerCode,
      removeContact: false,
      contactDetails: contactInfo
    })
  }));
};

const deleteProducerContact = (baseUrl: string, producerCode: string, contactId: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/producer-contacts/`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'PATCH',
    contentType: 'application/json',
    data: JSON.stringify({
      producerCode,
      removeContact: true,
      contactDetails: { PublicID: contactId }
    })
  }));
};

export {
  searchProducers,
  getProducerFeatureState,
  getProducerCodeHierarchy,
  getProducerContacts,
  createProducerContact,
  updateProducerContact,
  deleteProducerContact,
  getProducerCodeFromPhoneNumber
};
