import { from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import mapKeys from 'lodash/mapKeys';
import pickBy from 'lodash/pickBy';
import { getJwt } from '@/helpers/requests';
import { ZendeskTicket } from '@/helpers/types';

import client from './Client';

import { ZendeskError } from '../helpers/errors';
import { ZENDESK_URL_BASE, ZENDESK_FIELD_IDS } from '../helpers/settings';

const getFieldName = (id) => `ticket.customField:custom_field_${id}`;

const getFieldValues = (fieldLongNames: string[]) => {
  return from(client.get(fieldLongNames)).pipe(
    map((response: ZendeskResponse) => {
      const errors = response.errors ? Object.keys(response.errors) : [];

      if (errors.length) {
        throw new ZendeskError('Failed to get all field values', errors, response);
      } else if (Object.keys(response).length - 1 < fieldLongNames.length) {
        // This means that Zendesk has silently failed to return at least one field
        // ("errors" should also be one of the response's fields, even if there are no errors)
        console.error('Zendesk field update response:', response);
        throw new ZendeskError('Some expected field values were absent', [], response);
      } else {
        return response;
      }
    })
  );
};

const getFieldValue = (fieldName: string) => {
  return from(client.get(fieldName)).pipe(
    map((response: ZendeskResponse) => {
      return response[fieldName];
    })
  );
};

const getAutomationLevel = (ticketId: string) => {
  return from(client.request({
    url: `/api/v2/tickets/${ticketId}.json`,
    type: 'GET',
    contentType: 'application/json'
  })).pipe(
    map((response: ZendeskResponse) => {
      return response.ticket.custom_fields.find(
        (field) => {
          return field.id === parseInt(ZENDESK_FIELD_IDS.AUTOMATION_TYPE, 10);
        }
      ).value;
    })
  );
};

const getManualReviewReason = (ticketId: string) => {
  return from(client.request({
    url: `/api/v2/tickets/${ticketId}.json`,
    type: 'GET',
    contentType: 'application/json'
  })).pipe(
    map((response: ZendeskResponse) => {
      return response.ticket.custom_fields.find(
        (field) => {
          return field.id === parseInt(ZENDESK_FIELD_IDS.AUTOMATION_MANUAL_EXPLANATION, 10);
        }
      ).value;
    })
  );
};

const setFieldValues = (fieldsByLongName: Record<string, any>) => {
  return from(client.set(fieldsByLongName)).pipe(
    map((response: ZendeskResponse) => {
      return response;
    })
  );
};

const setFieldValuesIfEmpty = (fields: Record<string, any>) => {
  const fieldsByLongName = mapKeys(fields, (value, fieldKey) => getFieldName(fieldKey));

  return getFieldValues(Object.keys(fieldsByLongName)).pipe(
    switchMap((fieldValuesResponse) => {
      const fieldsToUpdate = pickBy(fieldsByLongName, (value, key) => {
        const currentValue = fieldValuesResponse[key];
        if (currentValue === null || currentValue === undefined || currentValue === '') {
          return true;
        }
        return false;
      });

      if (Object.keys(fieldsToUpdate).length) {
        return setFieldValues(fieldsToUpdate);
      }
      return of({});
    })
  );
};

const addTag = (tag: string) => {
  return from(client.get('ticket.id')).pipe(
    switchMap((response: ZendeskResponse) => {
      const ticketId = response['ticket.id'];
      return from(client.request({
        url: `/api/v2/tickets/update_many.json?ids=${ticketId.toString()}`,
        type: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({
          ticket: {
            additional_tags: [tag]
          }
        })
      }));
    })
  );
};

const getTags = async () => {
  const results = await from(client.get('ticket.tags')).toPromise();
  return results['ticket.tags'];
};

const getTicketRequester = async () => {
  const results = await from(client.get('ticket.requester')).toPromise();
  return results['ticket.requester'];
};

const getTicketOrganization = async () => {
  const results = await from(client.get('ticket.organization')).toPromise();
  return results['ticket.organization'];
};

const getTicketData = async (ticketId: string): Promise<ZendeskTicket> => {
  const response: any = await from(client.request({
    url: `/api/v2/tickets/${ticketId}.json`,
    type: 'GET',
    contentType: 'application/json'
  })).toPromise();

  return response.ticket;
};

const getFormStructure = (baseUrl: string, inquiryType: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/request-fields/${inquiryType}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

const addPrivateComment = (commentContent: string) => {
  return from(client.get('ticket.id')).pipe(
    switchMap((response: ZendeskResponse) => {
      const ticketId = response['ticket.id'];
      return from(client.request({
        url: `/api/v2/tickets/${ticketId.toString()}.json`,
        type: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({
          ticket: {
            comment: {
              body: commentContent,
              public: false
            }
          }
        })
      }));
    })
  );
};

const getTicketUrl = (ticketId: string) => {
  const queryParams = process.env.NODE_ENV === 'local' ? '?zat=true' : '';
  return `${ZENDESK_URL_BASE}/tickets/${ticketId}${queryParams}`;
};

export {
  setFieldValuesIfEmpty,
  addPrivateComment,
  addTag,
  getTags,
  getTicketRequester,
  getTicketOrganization,
  getFieldValue,
  getAutomationLevel,
  getManualReviewReason,
  getTicketUrl,
  getTicketData,
  getFormStructure
};
