

import { defineComponent, inject } from 'vue';
import moment from 'moment';
import { SQ_PROVIDE_KEY } from '@/helpers/settings';
import { getInvoiceDetails } from '../requests/Billing';
import { generateInvoiceLink } from '../helpers/invoices';
import manageSubscriptions from '../helpers/manageSubscriptions';

export default defineComponent({
  data() {
    return {
      invoiceInfo: null,
      isLoading: false,
      hasError: false
    };
  },

  props: {
    accountInfo: {
      type: Object
    }
  },

  setup() {
    const { addNamedSubscription } = manageSubscriptions();
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);

    return {
      addNamedSubscription,
      sqBaseUrl
    };
  },

  created() {
    this.fetchInvoiceDetails();
  },

  methods: {
    fetchInvoiceDetails() {
      if (this.accountInfo) {
        this.invoiceInfo = null;
        this.isLoading = true;
        this.hasError = false;

        this.addNamedSubscription(
          'billing',
          getInvoiceDetails(
            this.sqBaseUrl,
            this.accountInfo.AccountNumber,
            this.accountInfo.ProducerCodes.Entry[0].ProducerCode.Code
          ).subscribe((accountInfo: any) => {
            this.isLoading = false;
            this.hasError = false;
            this.invoiceInfo = accountInfo;
          }, () => {
            this.hasError = true;
          })
        );
      }
    },
    isOverdue(invoice: any) {
      return invoice.status === 'Due' && moment.utc(invoice.dueDate).isBefore(moment.utc().startOf('day'));
    },
    getDueDate(invoice: any) {
      return moment.utc(invoice.dueDate).format('M/D/YY');
    },
    getLink(invoice: any) {
      return generateInvoiceLink(invoice);
    }
  },

  watch: {
    accountInfo() {
      this.fetchInvoiceDetails();
    },
  },
});
