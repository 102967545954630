
import { defineComponent, inject } from 'vue';
import * as FileSaver from 'file-saver';
import get from 'lodash/get';

import { SQ_PROVIDE_KEY } from '../helpers/settings';
import {
  getDocumentsList,
  downloadDocument,
  downloadPolicyDocument,
  checkPolicyDocument
} from '../requests/Documents';
import DownloadIcon from './DownloadIcon.vue';

export default defineComponent({
  components: { DownloadIcon },
  props: {
    policyNumber: {
      type: String
    },
    accountNumber: {
      type: String
    },
    jobNumber: {
      type: String
    },
    product: {
      type: String
    },
    status: {
      type: String
    },
    period: {
      type: String
    },
    term: {
      type: String
    },
    transactionData: {
      type: Object
    },
  },

  created() {
    this.fetchDocumentList();
    this.checkDocumentAvailability();
  },

  data() {
    return {
      documentsList: null,
      isDownloading: false,
      hasAvailableCancellationDoc: false,
      hasAvailablePolicyDoc: false,
      hasDownloadError: false,
    };
  },

  setup() {
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);
    return { sqBaseUrl };
  },

  computed: {
    showRenewalNote(): boolean {
      const termNumber = this.term ? parseFloat(this.term) : 1;
      return (termNumber > 1) && this.status === 'Scheduled';
    },
    hasDocuments(): boolean {
      const documentsFromList = this.documentsList &&
        (this.documentsList.Cancellation ||
        this.documentsList.NonRenewal);
      return this.hasAvailablePolicyDoc || this.hasAvailableCancellationDoc || documentsFromList;
    },
    getCancellationJobNumber(): string {
      const termPeriods = get(this, 'transactionData.policyPeriodTransactions.PolicyTerm.PortalViewableTermPeriods.Entry', []);
      if (!termPeriods || !termPeriods.length) {
        return '';
      }
      const cancellationPeriod = termPeriods.find(
        (period) => period.Job && period.Job.DisplayType === 'Cancellation' && period.Status !== 'Withdrawn'
      );
      return cancellationPeriod ? cancellationPeriod.Job.JobNumber : null;
    }
  },

  methods: {
    fetchDocumentList() {
      getDocumentsList(this.sqBaseUrl, this.policyNumber, this.term).subscribe((list) => {
        this.documentsList = list;
      });
    },
    checkDocumentAvailability() {
      checkPolicyDocument(this.sqBaseUrl, this.jobNumber).subscribe((isAvailable) => {
        this.hasAvailablePolicyDoc = isAvailable;
      });

      const cancellationJobNumber = this.getCancellationJobNumber;
      if (cancellationJobNumber) {
        checkPolicyDocument(this.sqBaseUrl, cancellationJobNumber).subscribe((isAvailable) => {
          this.hasAvailableCancellationDoc = isAvailable;
        });
      }
    },
    downloadCancellationDocument() {
      const cancellationJobNumber = this.getCancellationJobNumber;
      this.isDownloading = true;
      this.hasDownloadError = false;
      downloadPolicyDocument(this.sqBaseUrl, cancellationJobNumber).subscribe((result) => {
        FileSaver.saveAs(result, `${this.policyNumber}-${this.term}-cancellation-document.pdf`);
        this.isDownloading = false;
      }, () => {
        this.isDownloading = false;
        this.hasDownloadError = true;
      });
    },
    downloadNoticeOfCancellation() {
      this.isDownloading = true;
      this.hasDownloadError = false;
      downloadDocument(this.sqBaseUrl, this.policyNumber, this.term, 'cancellation').subscribe((result) => {
        FileSaver.saveAs(result, `${this.policyNumber}-${this.term}-notice-of-cancellation.pdf`);
        this.isDownloading = false;
      }, () => {
        this.isDownloading = false;
        this.hasDownloadError = true;
      });
    },
    downloadNonRenewal() {
      this.isDownloading = true;
      this.hasDownloadError = false;
      downloadDocument(this.sqBaseUrl, this.policyNumber, this.term, 'non-renewal').subscribe((result) => {
        FileSaver.saveAs(result, `${this.policyNumber}-${this.term}-nonrenew.pdf`);
        this.isDownloading = false;
      }, () => {
        this.isDownloading = false;
        this.hasDownloadError = true;
      });
    },
    downloadPolicyDocument() {
      this.isDownloading = true;
      this.hasDownloadError = false;
      downloadPolicyDocument(this.sqBaseUrl, this.jobNumber).subscribe((result) => {
        FileSaver.saveAs(result, `${this.policyNumber}-${this.term}-document.pdf`);
        this.isDownloading = false;
      }, () => {
        this.isDownloading = false;
        this.hasDownloadError = true;
      });
    },
  }
});
