
import { defineComponent, inject, PropType } from 'vue';
import { tap, catchError } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { SQ_PROVIDE_KEY } from '@/helpers/settings';
import * as Dnd from '@/helpers/files';
import DragAndDropUploader from './DragAndDropUploader.vue';
import { SubmitFunction } from '../requests/Lob';

export default defineComponent({
  components: {
    DragAndDropUploader
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    },
    submitFunction: {
      type: Function as PropType<SubmitFunction>,
      required: true
    }
  },
  data() {
    return {
      observables: [],
      valid: true,
      spinner: false,
      combinedSubscription: null,
      validationMessage: '',
      uploadFiles: {
        items: [],
        allowedTypes: ['pdf']
      } as Dnd.DndFileList
    };
  },
  setup() {
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);
    return { sqBaseUrl };
  },
  methods: {
    addFiles(addFiles: FileList) {
      if (addFiles.length + this.uploadFiles.items.length > Dnd.MAX_FILES) {
        this.valid = false;
        this.validationMessage = `A maximum of ${Dnd.MAX_FILES} can be uploaded at at time`;
        return;
      }
      this.uploadFiles = Dnd.addFiles(this.uploadFiles, addFiles);
      console.log(this.uploadFiles);
    },
    deleteFile(entry: Dnd.DndFileEntry) {
      this.uploadFiles = Dnd.removeFile(this.uploadFiles, entry);
      console.log(this.uploadFiles);
    },
    showSpinner() {
      this.spinner = true;
    },
    hideSpinner() {
      this.spinner = false;
    },
    submit() {
      this.valid = this.validate();
      if (this.valid) {
        this.showSpinner();
        /* eslint-disable no-param-reassign */
        this.uploadFiles.items.forEach((uploadFile) => {
          // Skip uploading files that have already successfully uploaded
          if (uploadFile.status === Dnd.DndFileStatus.SUCCESS) {
            return;
          }

          this.observables.push(this.submitFunction(this.sqBaseUrl, uploadFile.file, this.currentUser.email).pipe(
            tap((res) => {
              // Mark uploaded files completed successfully
              if (res.status === 200) {
                uploadFile.status = Dnd.DndFileStatus.SUCCESS;
              // Add error message for files that did not upload successfully
              } else {
                res.json().then((val) => {
                  uploadFile.status = Dnd.DndFileStatus.ERROR;
                  uploadFile.errorMessage = val.message;
                });
              }
            }),
            catchError((err) => {
              uploadFile.status = Dnd.DndFileStatus.ERROR;
              uploadFile.errorMessage = err.message;
              return of(err);
            })
          ));
        });
        /* eslint-enable no-param-reassign */
        const combinedObservable = forkJoin(this.observables);
        this.combinedSubscription = combinedObservable.subscribe(() => {
          this.hideSpinner();
        });
      }
    },
    validate() {
      if (this.uploadFiles.items.some((entry) => entry.status === Dnd.DndFileStatus.INVALID_INPUT)) {
        this.validationMessage = 'Please remove invalid files from upload';
        return false;
      }

      return true;
    }
  },
  beforeUnmount() {
    if (this.combinedSubscription) {
      this.combinedSubscription.unsubscribe();
    }
  }
});
