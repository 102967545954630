
import { FieldType } from '@/helpers/types';
import { defineComponent } from 'vue';

const __default__ = defineComponent({
  props: {
    field: {
      type: Object
    },
    emailSummary: {
      type: String
    },
    depth: {
      type: Number
    }
  },
  data() {
    return {
      selectedAnswerValue: '',
      emailSummaryValue: ''
    };
  },
  computed: {
    isSelectNode() {
      return this.field.fieldType === FieldType.SELECT;
    },
    isAnswerNode() {
      return this.field.fieldType === FieldType.ANSWER;
    },
    getListStyleType() {
      const listItemTypes = ['disc', 'circle', 'square'];
      return listItemTypes[this.depth % listItemTypes.length];
    },
    getFormIndentation() {
      // Due to width limitations in the ZD app, we stop indenting after 2 levels
      return this.depth <= 2 ? '15px' : '0px';
    }
  },
  methods: {
    getValueFromEmailBody() {
      if (!this.emailSummary) {
        this.emailSummaryValue = '';
        return;
      }
      const summaryLines = this.emailSummary.split('\n');
      const matchingLine = summaryLines.find((part) => part.includes(this.field.fieldLabel));
      if (!matchingLine) {
        this.emailSummaryValue = 'Not provided';
        return;
      }
      const [_, value] = matchingLine.split(`${this.field.fieldLabel}: `);
      this.emailSummaryValue = value || 'Not provided';
    }
  },
  watch: {
    emailSummary: {
      immediate: true,
      handler() {
        this.getValueFromEmailBody();
      }
    }
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "466cabc5": (_ctx.getFormIndentation),
  "1cb116ed": (_ctx.getListStyleType)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__