// This mapping comes from the following spreadsheet, which is managed by the Finance Team:
// https://docs.google.com/spreadsheets/d/12sbbc0pptN15iMDvn2Ib89tBXrVeScWfuzgvimNEQCs
// TODO: Eventually, we should store this mapping in an S3 bucket
const COMMISSION_PLANS = {
  'bc:66033': 'Commission Plan - 5% New Only',
  'bc:31004': 'Commission Plan - 12.5% Flat',
  'commplan_Attune:1': 'Commission Plan - 15% Flat',
  'bc:27007': 'Commission Plan - 15%/12.5%',
  'commplan_Attune:16': 'Commission Plan - 17% Flat',
  'commplan_Attune:15': 'Commission Plan - 17.5% Flat',
  'commplan_Attune:5': 'Commission Plan - 17.5%/15%',
  'commplan_Attune:14': 'Commission Plan - 17.5%/16%',
  'bc:76003': 'Commission Plan - 17.5%/16.5%',
  'bc:2002': 'Commission Plan - 18.5%/17.5%',
  'commplan_Attune:4': 'Commission Plan - 18%/16%',
  'commplan_Attune:3': 'Commission Plan - 20% Flat',
  'commplan_Attune:13': 'Commission Plan - 20%/17.5%',
  'bc:20002': 'Commission Plan - 20%/18.5%',
  'bc:69005': 'Commission Plan - 21%/17.5%',
  'bc:55016': 'Commission Plan 0%',
  'bc:66014': 'Commission Plan 12%/10%',
  'bc:31005': 'Commission Plan 16%.15%',
  'bc:66007': 'Commission Plan 18.5%/17%',
  'bc:60003': 'Override Plan 14%/12.5% plus 3%',
  'bc:60006': 'Override Plan 15%/12.5% Plus 2.5%',
  'bc:94011': 'Override Plan 15%/12.5% plus 2.5%/1%',
  'bc:71002': 'Override Plan 15%/12.5% plus 2.5%/1.5%',
  'bc:61003': 'Override Plan 15%/12.5% plus 3%',
  'bc:79002': 'Override Plan 15%/12.5% & 3.5%/5%',
  'bc:50002': 'Override Plan 15%/12.5% & 4%/2.5%',
  'bc:69012': 'Override Plan 15%/15% Plus 2%/1%',
  'bc:73005': 'Override Plan 15%/15% plus 2.5%/1.5%',
  'bc:68008': 'Override Plan 15%/15% Plus 2.5% New Only',
  'bc:69009': 'Override Plan 15%/15% Plus 3%/2%',
  'bc:60002': 'Override Plan 15%/15% plus 3% / 2.5%',
  'bc:30006': 'Override Plan 15%/15% Plus 3% Override',
  'bc:60004': 'Override Plan 15%/15% Plus 5% Override',
  'bc:53003': 'Override Plan 15%/15%, Secondary 2.5%',
  'bc:67028': 'Override Plan 15.5%/13% plus 2%/2%',
  'bc:69014': 'Override Plan 15.5%/15% Plus 2%/1%',
  'bc:66034': 'Override Plan 15.5%/15% plus 2.5%',
  'bc:71001': 'Override Plan 15%/15% plus 2.5%2.5%',
  'bc:86006': 'Override plan 16%/15% plus 2%/2.5%',
  'bc:92014': 'Override Plan 16%/15% & 2.5%/1.5%',
  'bc:94014': 'Override plan 17%/15% plus 2.5%/1%',
  'bc:86004': 'Override Plan 17.5%/15% Plus 1%/2.5%',
  'bc:94013': 'Override plan 17.5%/15% plus 2.5%/1%',
  'bc:51001': 'Override Plan 17.5%/15% Plus 3% Override',
  'bc:92015': 'Override Plan 17.5%/15% Plus 2.5%/1.5%',
  'commplan_Attune:2': 'Override Plan 17.5%/15%, Secondary 2.5%',
  'bc:94012': 'Override plan 17.5%/16% plus 2.5%/1%',
  'bc:69008': 'Referral Plan 15%/12.5%',
  'bc:32004': 'Referral Plan 15%/15%',
  'bc:85002': 'Referral Plan 17.5% Flat',
  'bc:21006': 'Referral Plan 17.5%/15%',
  'bc:21007': 'Referral Plan 17.5%/16%',
  'bc:69013': 'Referral Plan 17.5%/17%',
  'bc:36002': 'Referral Plan 18%/15%',
  'bc:31015': 'Referral Plan 18%/17.5%',
  'bc:28004': 'Referral Plan 20% Flat',
  'bc:59001': 'Referral Plan 20%/15%',
  'bc:42002': 'Referral Plan 20%/17%',
  'bc:15001': 'Referral Plan 20%/17.5%',
  'bc:33005': 'Referral Plan 20%/18%',
  'commplan_Attune:7': 'Secondary Plan 17.5% Flat',
  'commplan_Attune:8': 'Secondary Plan 18%/16%',
  'commplan_Attune:6': 'SIAA Commission Plan',
  'bc:33004': 'Strategic Partner 11%/8.5%',
  'bc:87002': 'Three Tier 0%: 17.5%/15%, 1%/2.5%'
};

const NO_COMMISSION_PLANS = [
  'bc:36002',
  'bc:21007',
  'bc:59001',
  'bc:33005',
  'bc:21006',
  'bc:42002',
  'bc:69013',
  'bc:85002',
  'bc:15001',
  'bc:32004',
  'bc:31015',
  'bc:119002',
  'bc:69008',
  'bc:28004',
  'bc:87002',
  'commplan_Attune:7',
  'commplan_Attune:8'
];

enum TipaltiStatus {
  PAYABLE = 'Payable',
  UNPAYABLE = 'Unpayable',
  PAYEE_SETUP_MISSING = 'Agency not set up'
}

const getCommPlanReadable = (commPlanCode: string) => {
  return COMMISSION_PLANS[commPlanCode] || 'Unavailable';
};

const hasPayeeSetup = (payeeInfo): boolean => {
  return payeeInfo.PaymentMethod !== 'NoPM';
};

const getTipaltiStatus = (payeeInfo): TipaltiStatus => {
  if (!hasPayeeSetup(payeeInfo)) {
    return TipaltiStatus.PAYEE_SETUP_MISSING;
  }
  return payeeInfo.Payable ? TipaltiStatus.PAYABLE : TipaltiStatus.UNPAYABLE;
};

const isNoCommissionPlan = (commPlanCode: string) => {
  return NO_COMMISSION_PLANS.includes(commPlanCode);
};

export {
  getCommPlanReadable,
  getTipaltiStatus,
  hasPayeeSetup,
  isNoCommissionPlan
};
