export enum HelpCenterRequestAutomationLevel {
  FULL = 'fully_automated',
  PARTIAL = 'partially_automated',
  MANUAL = 'manual'
}

export enum HelpFormInquiryType {
  AdditionalInsured = 'policy__endorsements__add_/_update_additional_insured',
  ChangeCoverage = 'policy__endorsements__change_coverage',
  Location = 'policy__endorsements__add_/_update_location',
  NamedInsured = 'policy__endorsements__update_named_insured',
  RequestCancel = 'policy__cancellations__request_cancellation'
}

export const HCF_TICKET_DATA_VIEW_INQUIRY_TYPES = [
  HelpFormInquiryType.AdditionalInsured,
  HelpFormInquiryType.ChangeCoverage,
  HelpFormInquiryType.Location,
  HelpFormInquiryType.NamedInsured,
  HelpFormInquiryType.RequestCancel
];

export const ReadableInquiryTypeNames: Record<HelpFormInquiryType, string> = {
  [HelpFormInquiryType.AdditionalInsured]: 'Additional insured',
  [HelpFormInquiryType.ChangeCoverage]: 'Change coverage',
  [HelpFormInquiryType.Location]: 'Location',
  [HelpFormInquiryType.NamedInsured]: 'Named insured',
  [HelpFormInquiryType.RequestCancel]: 'Request cancellation'
};
