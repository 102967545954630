import { from } from 'rxjs';
import { getJwt } from '../helpers/requests';
import client from './Client';

const searchUsers = (baseUrl: string, query: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/user-search/${encodeURIComponent(query)}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

const getUserGroups = (baseUrl: string, login: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/user-groups/${encodeURIComponent(login)}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

export {
  searchUsers,
  getUserGroups
};
