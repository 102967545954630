import moment from 'moment';
import flatten from 'lodash/flatten';

const getLatestTerm = (policy: any) => {
  const terms = policy.PortalViewableNewTermPeriods.Entry;
  const filteredTerms = terms.filter(
    (term) => !['Non-renewed', 'Quoted'].includes(term.TermDisplayStatus_ATN)
  );
  return filteredTerms.reduce((acc, term) => {
    const isLater = moment(term.PeriodStart).isAfter(moment(acc.PeriodStart));
    return isLater ? term : acc;
  }, filteredTerms[0]);
};

const getLatestCancellationTerm = (policy: any) => {
  const terms = policy.PortalViewableNewTermPeriods.Entry;
  return terms.reduce((acc, nextTerm) => {
    const isCancellation = nextTerm.Job.Subtype === 'Cancellation' || nextTerm.TermDisplayStatus_ATN === 'Canceled';
    const isLater = acc ? moment(nextTerm.PeriodStart).isAfter(moment(acc.PeriodStart)) : true;
    if (isCancellation) {
      return isLater ? nextTerm : acc;
    }
    return acc;
  }, null);
};

const getLatestAccountCancellation = (accountInfo: any) => {
  const policyCancellations = accountInfo.Policies.Entry.map((policy) => getLatestCancellationTerm(policy));
  const latestCancel = policyCancellations.reduce((cancel, accCancel) => {
    if (!cancel) {
      return accCancel;
    }
    const isLaterTerm = accCancel ? moment(cancel.PeriodStart).isAfter(moment(accCancel.PeriodStart)) : accCancel;
    return isLaterTerm ? cancel : accCancel;
  }, null);
  return latestCancel;
};

const getLatestProductTerm = (account: any, product: string) => {
  const productPoliciesNested = account?.Policies?.Entry.map((policy) => {
    return policy.PortalViewableNewTermPeriods.Entry.filter((term) => {
      return term.LineBusinessType === product;
    });
  });
  const productPolicies = flatten(productPoliciesNested);
  if (productPolicies && productPolicies.length) {
    const latestPolicy = productPolicies.reduce(
      (previousPolicy: any, currentPolicy: any) => {
        const isLaterTerm = moment(currentPolicy.PeriodStart).isAfter(moment(previousPolicy.PeriodStart));
        return isLaterTerm ? currentPolicy : previousPolicy;
      },
      productPolicies[0]
    );
    return latestPolicy;
  }
  return null;
};

const getLatestBopTerm = (account: any) => {
  return getLatestProductTerm(account, 'Businessowners Line (v7)');
};

const getLatestXsTerm = (account: any) => {
  return getLatestProductTerm(account, 'Commercial Excess Liability Line');
};

const getPolicyListFlattened: any = (policy: any) => {
  return flatten(policy.Policies.Entry.map(
    (term) => term.PortalViewableNewTermPeriods.Entry
  ));
};

export {
  getLatestTerm,
  getLatestAccountCancellation,
  getLatestCancellationTerm,
  getLatestBopTerm,
  getLatestXsTerm,
  getPolicyListFlattened
};
