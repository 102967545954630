
import { SQ_PROVIDE_KEY } from '@/helpers/settings';
import { defineComponent, inject } from 'vue';

import CollapsibleSection from './CollapsibleSection.vue';
import manageSubscriptions from '../helpers/manageSubscriptions';
import { getProducerFeatureState } from '../requests/Producers';

export type Promotion = 'attune-wc-summer-incentive';

const promotionName: Record<Promotion, string> = {
  'attune-wc-summer-incentive': 'WC Summer Incentive &#9728;&#65039;'
};

export default defineComponent({
  components: {
    CollapsibleSection
  },
  props: {
    producerCode: {
      type: String
    },
    flag: {
      type: String
    },
  },
  data() {
    return {
      isCollapsed: false,
      hasPromotion: false
    };
  },
  setup() {
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);
    const { addSubscription } = manageSubscriptions();
    return { sqBaseUrl, addSubscription };
  },
  watch: {
    producerCode() {
      this.updateEligibility();
    },
    flag() {
      this.updateEligibility();
    }
  },
  created() {
    if (this.$props.producerCode) {
      this.updateEligibility();
    }
  },
  methods: {
    promotionName() {
      return promotionName[this.$props.flag];
    },
    toggle() {
      this.isCollapsed = !this.isCollapsed;
    },
    updateEligibility() {
      this.addSubscription(
        getProducerFeatureState(this.sqBaseUrl, this.$props.producerCode, this.$props.flag).subscribe(
          (result) => {
            this.hasPromotion = result.enabled === true;
          }
        )
      );
    }
  },
  computed: {},
});

