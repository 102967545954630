
import moment from 'moment';
import * as FileSaver from 'file-saver';
import { defineComponent, inject } from 'vue';

import { SQ_PROVIDE_KEY } from '@/helpers/settings';
import {
  CommissionStatementSummary,
  getDigitalCarriersAndAttuneCommissionStatements,
} from '../requests/Billing';
import { MONTHS } from '../helpers/datetime';
import CollapsibleSection from './CollapsibleSection.vue';
import manageSubscriptions from '../helpers/manageSubscriptions';
import {
  downloadAttuneStatement,
  downloadPartnerStatement
} from '../requests/Documents';

interface MonthlyDigitalAttuneStatement {
  statements: CommissionStatementSummary[];
  month: string,
  total: number
}

export default defineComponent({
  components: {
    CollapsibleSection
  },

  props: {
    producerCode: {
      type: String,
      required: true
    }
  },

  setup() {
    const { addNamedSubscription } = manageSubscriptions();
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);

    return {
      addNamedSubscription,
      sqBaseUrl
    };
  },

  created() {
    this.getDigitalCarriersAndAttuneCommissionStatements();
  },

  data() {
    return {
      startDate: moment().subtract(1, 'year').format(),
      months: MONTHS,
      digitalCarriersAndAttuneStatements: [] as CommissionStatementSummary[],
      collapsedStateByMonth: {} as Record<string, boolean>,
      downloadingPartnerStatement: {} as Record<string, boolean>,
      downloadingAttuneStatement: {} as Record<string, boolean>,
      errorDownloadingAttuneStatement: {} as Record<string, boolean>,
      errorDownloadingPartnerStatement: {} as Record<string, boolean>,
      isStatementsLoading: false,
      isStatementsLoaded: false,
      hasStatementsError: false,
    };
  },

  methods: {
    generateItemId(index: number) {
      return `item-${index}`;
    },
    getDigitalCarriersAndAttuneCommissionStatements() {
      this.isStatementsLoading = true;
      this.addNamedSubscription(
        'digital-carriers-and-partner-commission-statements',
        getDigitalCarriersAndAttuneCommissionStatements(this.sqBaseUrl, this.producerCode, this.startDate).subscribe(
          (statementsListResult) => {
            if (statementsListResult.data) {
              this.digitalCarriersAndAttuneStatements = statementsListResult.data;
            }

            this.isStatementsLoading = false;
            this.isStatementsLoaded = true;
            this.hasStatementsError = false;
          },
          () => {
            this.isStatementsLoading = false;
            this.hasStatementsError = true;
          }
        )
      );
    },
    loadMore() {
      this.startDate = moment(this.startDate).subtract(1, 'year').format();
      this.getDigitalCarriersAndAttuneCommissionStatements();
    },
    isStatementsCollapsed(month) {
      // Statements should be collapsed by default, so undefined = true
      // eslint-disable-next-line no-unneeded-ternary
      return (this.collapsedStateByMonth[month] === false) ? false : true;
    },
    toggleStatementsCollapsed(month) {
      if (this.collapsedStateByMonth[month] === undefined) {
        this.collapsedStateByMonth[month] = false;
      } else {
        this.collapsedStateByMonth[month] = !this.collapsedStateByMonth[month];
      }
    },
    formatDollars(dollars) {
      return dollars.toLocaleString('en-IN', { style: 'currency', currency: 'USD' });
    },
    getStatementAmount(statement: CommissionStatementSummary) {
      return statement.payoutAmount || statement.statementAmount;
    },
    downloadStatementFile(month: string, statement: CommissionStatementSummary) {
      if (statement.commissionType === 'Attune') {
        this.downloadingAttuneStatement[month] = true;
        downloadAttuneStatement(this.sqBaseUrl, this.producerCode, statement.statementNumber).subscribe(
          (result) => {
            FileSaver.saveAs(result, `${this.producerCode}-statement-${statement.statementNumber} ${month}.xls`);
            this.downloadingAttuneStatement[month] = false;
          },
          () => {
            this.errorDownloadingAttuneStatement[month] = true;
          }
        );
      } else if (statement.commissionType === 'Partner') {
        this.downloadingPartnerStatement[month] = true;
        downloadPartnerStatement(this.sqBaseUrl, this.producerCode, statement.statementCreated).subscribe(
          (result) => {
            FileSaver.saveAs(result, `${this.producerCode}-partner-statement ${month}.pdf`);
            this.downloadingPartnerStatement[month] = false;
          },
          () => {
            this.errorDownloadingPartnerStatement[month] = true;
          }
        );
      }
    },
    compareMonths(a, b) {
      const dateA = moment(a.month, 'MMMM YYYY');
      const dateB = moment(b.month, 'MMMM YYYY');

      if (dateA.year() !== dateB.year()) {
        return dateB.year() - dateA.year();
      }

      return dateB.month() - dateA.month();
    }
  },
  computed: {
    statementsByMonth(): MonthlyDigitalAttuneStatement[] {
      const statementsByMonth: Record<string, MonthlyDigitalAttuneStatement> = this.digitalCarriersAndAttuneStatements.reduce((acc, statement) => {
        // Group Partner commissions from the end of the month into the following month to match CCC expectations
        const startOfMonth = statement.commissionType === 'Partner'
          ? moment(statement.statementCreated).add(2, 'days').startOf('month')
          : moment(statement.statementCreated).startOf('month');
        const currentMonth = startOfMonth.format('MMMM YYYY');
        if (acc[currentMonth]) {
          acc[currentMonth].statements.push(statement);
          acc[currentMonth].total += statement.statementAmount;
        } else {
          acc[currentMonth] = {
            month: currentMonth,
            statements: [statement],
            total: statement.statementAmount
          };
        }
        return acc;
      }, {});
      return Object.values(statementsByMonth).slice().sort(this.compareMonths);
    }
  }

});
