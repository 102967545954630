
import moment from 'moment';
import { defineComponent, inject } from 'vue';
import { getLatestBopTerm, getLatestXsTerm } from '../helpers/ParseAccounts';
import { getReinstatementInfo, createReinstatementInvoice, createReinstatement } from '../requests/Policies';
import { getInvoiceDetails, searchFinanceAdmins } from '../requests/Billing';
import manageSubscriptions from '../helpers/manageSubscriptions';
import { CANCEL_REASON_LABELS } from '../helpers/cancellations';
import { AGENTPORTAL_URL_BASE, SQ_PROVIDE_KEY } from '../helpers/settings';
import { addPrivateComment } from '../requests/TicketFields';

export default defineComponent({
  props: {
    accountInfo: {
      type: Object,
      default: null
    },
    userOktaInfo: {
      type: Object
    },
    currentUser: {
      type: Object
    },
  },

  data() {
    return {
      reinstatementInfo: null,
      reinstatementInvoice: null,
      reinstatementInvoiceLink: null,
      latestTermBop: null,
      latestTermXs: null,
      ineligibleReason: null,
      cancellations: null,
      financeAdminInfo: null,
      policyNumberBop: null,
      policyNumberXs: null,
      hasPolicies: true,
      hasPaidReinstatements: false,
      hasPendingReinstatements: false,
      hasInitiatedInvoiceCreation: false,
      hasInitiatedReinstatementCreation: false,
      isEligible: false,
      isLoadingReinstatement: false,
      isLoadingNewInvoice: false,
      isLoadingExistingInvoices: false,
      isReinstatingPolicy: false,
      hasReinstatementLoadingError: false,
      hasReinstatementCreationErrorBop: false,
      hasReinstatementCreationErrorXs: false,
      hasInvoiceLoadingError: false,
      hasInvoiceCreationErrorBop: false,
      hasInvoiceCreationErrorXs: false,
    };
  },

  setup() {
    const { addSubscription } = manageSubscriptions();
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);

    return {
      addSubscription,
      sqBaseUrl
    };
  },

  created() {
    this.refreshData();
  },

  methods: {
    refreshData() {
      this.latestTermBop = getLatestBopTerm(this.accountInfo);
      this.latestTermXs = getLatestXsTerm(this.accountInfo);
      this.hasPolicies = true;
      if (!this.latestTermBop) {
        this.isLoadingReinstatement = false;
        this.hasPolicies = false;
        return;
      }
      this.policyNumberBop = this.latestTermBop.PolicyNumber;
      this.policyNumberXs = this.latestTermXs?.PolicyNumber;
      this.fetchReinstatementInfo(this.policyNumberBop);
      this.fetchReinstatementInvoice();
    },

    getInvoiceLink(reinstatementInvoice) {
      return `${AGENTPORTAL_URL_BASE}/bop/invoice/${reinstatementInvoice.id}/?token=${reinstatementInvoice.transactionId}`;
    },

    getFinanceAdminsReadable(financeAdminInfo) {
      if (!financeAdminInfo) {
        return 'Unavailable';
      }
      const financeAdminNamesReadable = financeAdminInfo.map(
        (info) => {
          if (!info.first_name || !info.last_name) {
            return `Finance Admin <${info.login}>`;
          }
          return `${info.first_name} ${info.last_name} <${info.login}>`;
        }
      );
      return financeAdminInfo.length ? financeAdminNamesReadable.join(', ') : 'Unavailable';
    },

    fetchFinanceAdminInfo() {
      this.financeAdminInfo = null;

      this.addSubscription(
        searchFinanceAdmins(
          this.sqBaseUrl,
          this.accountInfo?.ProducerCodes?.Entry[0]?.ProducerCode?.Code
        ).subscribe(
          (financeAdminInfo) => {
            this.financeAdminInfo = financeAdminInfo;
          }
        )
      );
    },

    getReinstatementEligibility() {
      // Policy is not cancelled
      if (!this.cancellations || this.reinstatementInfo.policyStatus !== 'Canceled') {
        this.ineligibleReason = 'Policy status';
        return false;
      }
      const latestCancellation = this.getLatestCancellation();
      // Policy's latest cancellation was for reason other than non-payment and not-taken
      if (latestCancellation.reason !== 'nonpayment' && latestCancellation.reason !== 'nottaken') {
        this.ineligibleReason = 'Cancellation reason';
        return false;
      }
      const hasPriorPassiveCancellations = this.hasPriorPassiveCancellationsInCurrentPolicyPeriod();
      // Policy has already been cancelled for non-payment 1 or more times in latest policy period
      if (hasPriorPassiveCancellations) {
        this.ineligibleReason = 'Prior cancellations';
        return false;
      }
      const latestCancellationDate = moment(latestCancellation.cancellationDate);
      const thirtyDaysAgo = moment.utc().subtract(30, 'days');
      // Policy's latest cancellation transaction was bound more than 30 days ago
      if (latestCancellationDate.isBefore(thirtyDaysAgo)) {
        this.ineligibleReason = 'Cancellation date';
        return false;
      }
      // Policy is eligible for every other case
      return true;
    },

    fetchReinstatementInfo(policyNumber: string) {
      this.reinstatementInfo = null;
      this.cancellations = null;
      this.isEligible = false;
      this.isLoadingReinstatement = true;
      this.hasReinstatementLoadingError = false;

      this.addSubscription(
        getReinstatementInfo(
          this.sqBaseUrl,
          policyNumber
        ).subscribe(
          (reinstatementInfo) => {
            this.reinstatementInfo = reinstatementInfo;
            this.cancellations = this.reinstatementInfo.cancellationHistory?.Entry;
            this.isEligible = this.getReinstatementEligibility();
            this.isLoadingReinstatement = false;
            this.hasReinstatementLoadingError = false;
          },
          () => {
            this.isLoadingReinstatement = false;
            this.hasReinstatementLoadingError = true;
          }
        )
      );
    },

    fetchReinstatementInvoice() {
      this.reinstatementInvoice = null;
      this.reinstatementInvoiceLink = null;
      this.hasPendingReinstatements = false;

      if (this.accountInfo) {
        this.isLoadingExistingInvoices = true;

        this.addSubscription(
          getInvoiceDetails(
            this.sqBaseUrl,
            this.accountInfo.AccountNumber,
            this.accountInfo?.ProducerCodes?.Entry[0]?.ProducerCode?.Code
          ).subscribe((invoiceInfo: any) => {
            this.isLoadingExistingInvoices = false;
            this.reinstatementInvoice = invoiceInfo.invoices.find(
              (invoice) => {
                return invoice.isReinstatementInvoice && invoice.lineItems.some((item) => item.isPendingReinstatement);
              }
            ) || null;
            if (this.reinstatementInvoice) {
              this.hasInitiatedInvoiceCreation = true;
              this.hasPendingReinstatements = true;
              this.hasPaidReinstatements = this.reinstatementInvoice.status === 'Paid';
              this.reinstatementInvoiceLink = this.getInvoiceLink(this.reinstatementInvoice);
            }
          }, () => {
            this.isLoadingExistingInvoices = false;
            this.hasInvoiceLoadingError = true;
            this.reinstatementInvoice = null;
          })
        );
      }
    },

    createInvoice() {
      this.isLoadingNewInvoice = true;
      this.hasInitiatedInvoiceCreation = true;
      this.hasInvoiceCreationErrorBop = false;
      this.hasInvoiceCreationErrorXs = false;

      const invoiceCreationSuccessHandler = () => {
        this.isLoadingNewInvoice = false;
        this.hasPendingReinstatements = true;
        this.refreshData();
        addPrivateComment(
          `Created reinstatement invoice for policy ${this.policyNumberBop} on account ${this.accountInfo.AccountNumber} (user: ${this.currentUser.email})`
        ).subscribe();
      };

      const invoiceCreationErrorHandlerBop = () => {
        this.isLoadingNewInvoice = false;
        this.hasInvoiceCreationErrorBop = true;
        this.hasPendingReinstatements = true;
        this.refreshData();
      };

      const invoiceCreationErrorHandlerXs = () => {
        this.isLoadingNewInvoice = false;
        this.hasInvoiceCreationErrorXs = true;
        this.hasPendingReinstatements = true;
        this.refreshData();
      };

      if (this.policyNumberXs) {
        this.addSubscription(
          createReinstatementInvoice(
            this.sqBaseUrl,
            this.policyNumberBop,
            this.userOktaInfo.profile.gwUserName
          ).subscribe(
            () => {
              this.addSubscription(
                createReinstatementInvoice(
                  this.sqBaseUrl,
                  this.policyNumberXs,
                  this.userOktaInfo.profile.gwUserName
                ).subscribe(
                  invoiceCreationSuccessHandler,
                  invoiceCreationErrorHandlerXs
                )
              );
            },
            invoiceCreationErrorHandlerBop
          )
        );
      } else {
        this.addSubscription(
          createReinstatementInvoice(
            this.sqBaseUrl,
            this.policyNumberBop,
            this.userOktaInfo.profile.gwUserName
          ).subscribe(
            invoiceCreationSuccessHandler,
            invoiceCreationErrorHandlerBop
          )
        );
      }
    },

    reinstatePolicy() {
      this.isReinstatingPolicy = true;
      this.hasInitiatedReinstatementCreation = true;
      this.hasReinstatementCreationErrorBop = false;
      this.hasReinstatementCreationErrorXs = false;

      const reinstatementCreationSuccessHandler = () => {
        this.isReinstatingPolicy = false;
        this.refreshData();
        addPrivateComment(
          `Reinstated policy ${this.policyNumberBop} on account ${this.accountInfo.AccountNumber} (user: ${this.currentUser.email})`
        ).subscribe();
      };

      const reinstatementCreationErrorHandlerBop = () => {
        this.isReinstatingPolicy = false;
        this.hasReinstatementCreationErrorBop = true;
        this.refreshData();
      };

      const reinstatementCreationErrorHandlerXs = () => {
        this.isReinstatingPolicy = false;
        this.hasReinstatementCreationErrorXs = true;
        this.refreshData();
      };

      if (this.policyNumberXs) {
        this.addSubscription(
          createReinstatement(
            this.sqBaseUrl,
            this.policyNumberBop,
            this.userOktaInfo.profile.gwUserName
          ).subscribe(
            () => {
              this.addSubscription(
                createReinstatement(
                  this.sqBaseUrl,
                  this.policyNumberXs,
                  this.userOktaInfo.profile.gwUserName
                ).subscribe(
                  reinstatementCreationSuccessHandler,
                  reinstatementCreationErrorHandlerXs
                )
              );
            },
            reinstatementCreationErrorHandlerBop
          )
        );
      } else {
        this.addSubscription(
          createReinstatement(
            this.sqBaseUrl,
            this.policyNumberBop,
            this.userOktaInfo.profile.gwUserName
          ).subscribe(
            reinstatementCreationSuccessHandler,
            reinstatementCreationErrorHandlerBop
          )
        );
      }
    },

    getLatestCancellation() {
      return this.cancellations.reduce((previousCancellation, currentCancellation) => {
        const currentCancellationDate = moment(currentCancellation.cancellationDate);
        const previousCancellationDate = moment(previousCancellation.cancellationDate);
        const isLaterCancellation = currentCancellationDate.isAfter(previousCancellationDate);
        return isLaterCancellation ? currentCancellation : previousCancellation;
      });
    },

    hasPriorPassiveCancellationsInCurrentPolicyPeriod() {
      const priorPassiveCancellations = this.cancellations.filter(
        (cancellation) => {
          if (!this.latestTermBop.PeriodStart || !this.latestTermBop.PeriodEnd) {
            return false;
          }
          const cancellationDate = moment(cancellation.cancellationDate);
          const periodStartDate = moment(this.latestTermBop.PeriodStart);
          const periodEndDate = moment(this.latestTermBop.PeriodEnd);
          const isInCurrentPolicyPeriod = cancellationDate.isSameOrAfter(periodStartDate) && cancellationDate.isSameOrBefore(periodEndDate);
          const isPassiveCancellation = cancellation.reason === 'nonpayment' || cancellation.reason === 'nottaken';
          return isInCurrentPolicyPeriod && isPassiveCancellation;
        }
      );
      return priorPassiveCancellations.length > 1;
    },

    getCancellationLabel(reason: string) {
      return CANCEL_REASON_LABELS[reason] || `${reason}`;
    }
  },

  computed: {
    accountContact(): string {
      if (this.accountInfo) {
        return this.accountInfo.AccountHolderContact.EmailAddress1;
      }
      return 'Unavailable';
    }
  },

  watch: {
    accountInfo() {
      this.refreshData();
    }
  }
});
