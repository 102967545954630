
import { SQ_PROVIDE_KEY } from '@/helpers/settings';
import { timer } from 'rxjs';
import { defineComponent, inject } from 'vue';

import CollapsibleSection from './CollapsibleSection.vue';
import { trackEvent } from '../helpers/amplitude';
import { setProductFeedback } from '../requests/ProductFeedback';

export default defineComponent({
  components: {
    CollapsibleSection
  },
  props: {
    currentUser: {
      type: Object
    },
    producerCode: {
      type: String
    },
    ticketId: {
      type: String
    },
    userId: {
      type: String
    }
  },
  data() {
    return {
      comment: '',
      error: '',
      isSubmittedAndHidden: false,
      isCollapsed: false,
      isSubmitted: false,
    };
  },
  setup() {
    const sqBaseUrl: string = inject(SQ_PROVIDE_KEY);
    return { sqBaseUrl };
  },
  mounted() {
    trackEvent('feedback_form_show', this.ticketId, 'ProductFeedbackSection');
  },
  watch: {
    ticketId() {
      trackEvent('feedback_form_show', this.ticketId, 'ProductFeedbackSection');
    }
  },
  methods: {
    submit() {
      trackEvent('feedback_form_submit', this.ticketId, 'ProductFeedbackSection');
      setProductFeedback(
        this.sqBaseUrl,
        'attune_gw',
        'wc',
        this.producerCode,
        this.userId,
        {
          comment: this.comment,
          submitterEmail: this.currentUser.email,
          ticketId: this.ticketId,
        }
      ).subscribe(() => {
        this.error = '';
        this.isSubmitted = true;
        // Wait 3s to hide the section so we can show a confirmation message
        timer(3000).subscribe(() => {
          this.isSubmittedAndHidden = true;
        });
      }, (e) => {
        if (e && e.status === 409) {
          this.error = 'Error: feedback was already collected for this user.';
        } else {
          this.error = 'Unknown error: try again or contact #help-eng.';
        }
      });
    },
    toggle() {
      this.isCollapsed = !this.isCollapsed;
    }
  },
  computed: {}
});
