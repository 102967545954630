import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import client from './Client';
import { ZendeskError } from '../helpers/errors';

const getCurrentUser = () => {
  return from(
    client.get('currentUser')
  ).pipe(
    map((response: ZendeskResponse) => {
      const errors = response.errors ? Object.keys(response.errors) : [];

      if (errors.length) {
        throw new ZendeskError('Failed to get all field values', errors, response);
      } else if (!response.currentUser) {
        throw new ZendeskError('Some expected field values were absent', [], response);
      } else {
        return response.currentUser;
      }
    })
  );
};

export {
  getCurrentUser
};
