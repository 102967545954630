class ZendeskError extends Error {
  errorFields = [];

  response = {};

  constructor(message, errorFields: string[], response: ZendeskResponse) {
    super(message);
    this.errorFields = errorFields;
    this.response = response;
  }
}

export {
  ZendeskError
};
