import { Observable, from } from 'rxjs';
import { getJwt } from '../helpers/requests';
import client from './Client';

interface CheckProductFeedback {
  hasProductAccess: boolean;
  hasSubmittedFeedback: boolean;
}

const checkProductFeedback = (baseUrl: string, pasSource: string, product: string, producerCode: string, userId: string): Observable<CheckProductFeedback> => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/general-product-feedback/${pasSource}/${product}/producer-code/${producerCode}/${userId}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  })) as Observable<CheckProductFeedback>;
};

interface SetProductFeedback {
  comment: string;
  submitterEmail: string;
  ticketId: string;
}

const setProductFeedback = (baseUrl: string, pasSource: string, product: string, producerCode: string, userId: string, productFeedback: SetProductFeedback) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/general-product-feedback/${pasSource}/${product}/producer-code/${producerCode}/${userId}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify(productFeedback)
  }));
};

export {
  checkProductFeedback,
  setProductFeedback,
};
