import { from } from 'rxjs';

import client from './Client';
import { getJwt } from '../helpers/requests';

const getEmailSummary = (baseUrl: string, ticketId: string, inboundInquiryType: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/tickets/${ticketId}/email-summary?inboundInquiryType=${inboundInquiryType}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

export {
  getEmailSummary
};
