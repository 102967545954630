import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b8c57cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass({ bordered: !_ctx.hideBorder })
  }, [
    (_ctx.collapsed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("a", {
            href: "",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.toggleCollapse()), ["prevent"])),
            class: "js-collapsible-expand"
          }, [
            _createElementVNode("img", {
              src: `${_ctx.publicPath}icons/chevron-down.svg`,
              alt: "expand"
            }, null, 8, _hoisted_2)
          ]),
          _renderSlot(_ctx.$slots, "caption", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.collapsed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("a", {
            href: "",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.toggleCollapse()), ["prevent"])),
            class: "js-collapsible-collapse"
          }, [
            _createElementVNode("img", {
              src: `${_ctx.publicPath}icons/chevron-up.svg`,
              alt: "collapse"
            }, null, 8, _hoisted_4)
          ]),
          _renderSlot(_ctx.$slots, "caption", {
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.toggleCollapse()), ["prevent"]))
          }, undefined, true),
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}