import { from, Observable } from 'rxjs';
import { getJwt } from '../helpers/requests';

type SubmitFunction = (baseUrl: string, packet: File, email: string) => Observable<Response>;

const submitRolloverPacket: SubmitFunction = (baseUrl: string, packet: File, email: string) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('packet', packet);

  return from(
    fetch(
      `${baseUrl}/v3/external/zd/uploadRolloverPacket`,
      {
        method: 'post',
        body: formData,
        headers: { Authorization: `Bearer ${getJwt()}`, Accept: 'application/json' }
      }
    )
  );
};

const submitNonRenewalNotice: SubmitFunction = (baseUrl: string, packet: File, email: string) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('packet', packet);

  return from(
    fetch(
      `${baseUrl}/v3/external/zd/uploadNonRenewalNotice`,
      {
        method: 'post',
        body: formData,
        headers: { Authorization: `Bearer ${getJwt()}`, Accept: 'application/json' }
      }
    )
  );
};

const submitNonPayNotice: SubmitFunction = (baseUrl: string, packet: File, email: string) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('packet', packet);

  return from(
    fetch(
      `${baseUrl}/v3/external/zd/uploadNonPayNotice`,
      {
        method: 'post',
        body: formData,
        headers: { Authorization: `Bearer ${getJwt()}`, Accept: 'application/json' }
      }
    )
  );
};

const submitUWCancellationNotice: SubmitFunction = (baseUrl: string, packet: File, email: string) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('packet', packet);

  return from(
    fetch(
      `${baseUrl}/v3/external/zd/uploadUWCancellationNotice`,
      {
        method: 'post',
        body: formData,
        headers: { Authorization: `Bearer ${getJwt()}`, Accept: 'application/json' }
      }
    )
  );
};

export {
  submitRolloverPacket,
  submitNonPayNotice,
  submitNonRenewalNotice,
  submitUWCancellationNotice,
  SubmitFunction
};
