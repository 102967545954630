import { from, Observable } from 'rxjs';
import { getJwt } from '../helpers/requests';

import client from './Client';

const getInvoiceDetails = (baseUrl: string, accountId: string, producerCode: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/account/invoices`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    data: JSON.stringify({
      accountNumber: accountId,
      producerCode
    }),
    secure: true,
    type: 'POST',
    cors: false,
    contentType: 'application/json'
  }));
};

const getCommissionUser = (baseUrl: string, producerCode: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/commission-user/${producerCode}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

type CommissionType = 'Attune' | 'Partner';

interface CommissionStatementSummaryBase {
  commissionType: CommissionType;
  statementCreated: string;
  statementAmount: number;
  payoutAmount: number;
}

interface AttuneCommissionStatementSummary
  extends CommissionStatementSummaryBase {
  commissionType: 'Attune';
  statementNumber: string;
}

interface PartnerCommissionStatementSummary
  extends CommissionStatementSummaryBase {
  commissionType: 'Partner';
}

type CommissionStatementSummary =
  | AttuneCommissionStatementSummary
  | PartnerCommissionStatementSummary;

const getDigitalCarriersAndAttuneCommissionStatements
  = (baseUrl: string, producerCode: string, startDate: string): Observable<{ data: CommissionStatementSummary[] }> => {
    return from(client.request({
      url: `${baseUrl}/v3/external/zd/producer-code/${producerCode}/commissions/statements?fromDate=${encodeURIComponent(startDate)}`,
      headers: { Authorization: `Bearer ${getJwt()}` },
      secure: true,
      type: 'GET',
      contentType: 'application/json'
    })) as Observable<{ data: CommissionStatementSummary[] }>;
  };

const searchPayees = (baseUrl: string, producerCode: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/commission-payee-details/${producerCode}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

const searchFinanceAdmins = (baseUrl: string, producerCode: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/producer-finance-admins/${producerCode}`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

export {
  getInvoiceDetails,
  getCommissionUser,
  getDigitalCarriersAndAttuneCommissionStatements,
  searchPayees,
  searchFinanceAdmins,

  // Interfaces
  CommissionStatementSummary,
};
