import { ZENDESK_GROUP_IDS } from './settings';
import { UserInfo } from './types';

// Set of users (people on the engineering team, and senior CCC people) who should see all features
const alwaysOnUsers = [
  // engineering
  'wcumbie@attuneinsurance.com',
  'akammert@attuneinsurance.com',
  'akammert+staging@attuneinsurance.com',
  'kezike@attuneinsurance.com',
  'kezike+staging@attuneinsurance.com',
  'awitcher@attuneinsurance.com',
  'hpatel@attuneinsurance.com',
  'ejones@attuneinsurance.com',
  'nyafai@attuneinsurance.com',
  'bcortez@attuneinsurance.com',
  // ccc
  'jleyva@attuneinsurance.com',
  'dhoopes@attuneinsurance.com',
  'koneil@attuneinsurance.com',
  'cbarnum@attuneinsurance.com',
  'crodriguez@attuneinsurance.com',
  // ops
  'tsullivan@attuneinsurance.com',
];

// Initially limited to a pilot group of BSAs
const productFeedbackUsers = [
  'internal@attuneinsurance.com',
  'nyafai@attuneinsurance.com',
  'areum@attuneinsurance.com',
  'pvalverde@attuneinsurance.com',
  'bandrade@attuneinsurance.com',
  'jcole@attuneinsurance.com',
  'awitcher@attuneinsurance.com',
];

const userHasGroup = (user: UserInfo, groupId: number) => {
  if (!user || !user.groups || !groupId) {
    return false;
  }
  return Object.values(user.groups).some((item) => item.id === groupId);
};

const hasCancellation = (user: UserInfo) => {
  return alwaysOnUsers.includes(user.email) || userHasGroup(user, ZENDESK_GROUP_IDS.CANCELLATION);
};

const hasReinstatement = (user: UserInfo) => {
  return alwaysOnUsers.includes(user.email) || userHasGroup(user, ZENDESK_GROUP_IDS.REINSTATEMENT);
};

const hasProducerSearch = (user: UserInfo) => {
  return alwaysOnUsers.includes(user.email) || userHasGroup(user, ZENDESK_GROUP_IDS.COMMISSION);
};

const hasLobAccess = (user: UserInfo) => {
  return alwaysOnUsers.includes(user.email) || userHasGroup(user, ZENDESK_GROUP_IDS.LOB);
};

const hasProductFeedback = (user: UserInfo) => {
  return productFeedbackUsers.includes(user.email);
};

export {
  hasCancellation,
  hasReinstatement,
  hasProducerSearch,
  hasLobAccess,
  hasProductFeedback
};
