import { from } from 'rxjs';
import moment from 'moment';
import client from './Client';
import { getJwt } from '../helpers/requests';

const getHelpCenterRequest = (baseUrl: string, ticketId: string) => {
  return from(client.request({
    url: `${baseUrl}/v3/external/zd/help-center-tickets/${ticketId}/request`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    secure: true,
    type: 'GET',
    contentType: 'application/json'
  }));
};

const resolveHelpCenterRequest = (baseUrl: string, ticketId: string, requestId: string, action: 'approve' | 'reject', user: string, cancelReason: string, effectiveDate: string) => {
  const resolveData: any = {
    action,
    user,
    requestId
  };

  if (cancelReason && effectiveDate) {
    resolveData.cancelReason = cancelReason;
    resolveData.effectiveDate = moment.utc(effectiveDate).format();
  }

  return from(client.request({
    url: `${baseUrl}/v3/external/zd/help-center-tickets/${ticketId}/resolve`,
    headers: { Authorization: `Bearer ${getJwt()}` },
    data: JSON.stringify(resolveData),
    secure: true,
    type: 'POST',
    contentType: 'application/json'
  }));
};

export {
  getHelpCenterRequest,
  resolveHelpCenterRequest
};
